import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, Navigate} from "react-router-dom";
import { AuthService, IAuthService } from "./auth-service";
import { ILoginRequest } from "./model/dto";
import hifzcenterloginicon from '../../image/hifzcenterloginicon.png';
import { RefreshButton } from "../refresh-button/refresh-button";
import { useTouchScreenLongPressEffect } from "../touch-screen-long-press/touch-screen-long-press";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import LoginIcon  from "../../image/frontepagelogo.png";
import backGround from '../../image/frontpagebackground.png';

export interface Login {}

interface ILoginErrors{
  emailError: string;
  passwordError:string;
}

export function Login(props: Login) {
  const [loginState, setLoginState] = useState({} as ILoginRequest);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [redirectToNewPassword, setRedirectToNewPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customError, setCustomError] = useState("");
  const [errorState, setErrorState] = useState({
    error: { } as ILoginErrors
  });

  const authService: IAuthService = new AuthService();

  const isLongPress = useTouchScreenLongPressEffect();

  async function handleLogin() {

     setIsLoading(true);

     if(hasError()){
      setIsLoading(false);
      return;
     } 

      const {authData, error} = await authService.login(loginState);

      if(error){
        setCustomError(error);
      }
      else{
        if (authData && authData.accessToken) {

          if(authData.isTemporaryPassword){
            setRedirectToNewPassword(true);
          }

          localStorage.setItem("authData", JSON.stringify(authData));
          setRedirectToReferrer(true);
        }
      }

      setIsLoading(false);
  }

  function handleChangeEmail(event: any) {
    setLoginState({ ...loginState, email: event.target.value });
  }

  function handleChangePassword(event: any) {
    setLoginState({ ...loginState, password: event.target.value });
  }

  const hasError = ()=> {
 
    const errors = {error: {
      ...errorState.error, 
      emailError: (!loginState.email || !isValidEmail(loginState.email)) ? "Email is not valid" : "",
      passwordError: !loginState.password ? "Password is not valid" : "" 
     }};

    setErrorState(errors);

    if(errors.error.emailError || errors.error.passwordError){
      return true;
    }

    return false;
    
  }

  const isValidEmail = (email:any) => {
    // Email regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function loginRender() {
    
    if(redirectToNewPassword){
      return <Navigate to="/newpassword"  />;
    }

    if (redirectToReferrer) {
      return  <Navigate to="/"  />;
    }
    
    return (
    <div style={{
              backgroundImage: `url(${backGround})`, 
              height:"100%",
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',}}>
      {isLongPress && <div style={{
          position:"absolute", 
          zIndex:"9999999",  
          overflow:"visible",
          marginLeft:"45%", 
          marginTop: "20%", 
          backgroundColor: "white",
          borderRadius:"50%",
          width:"32px",
          height:"32px"}}>
          <RefreshButton showRefreshIcon={isLongPress} />
      </div>}
       
        <div style={{marginLeft: "30%", marginRight: "30%"}}>
             <img style={{marginTop:"100px", marginBottom: "80px",marginLeft: "61px"
                }} src={LoginIcon} alt="Logo" />
              <br></br>
             <label style={{
                marginBottom: "5px",
                fontFamily: 'Heiti SC, sans-serif',
                fontWeight: "400",
                fontSize:"16px" }}>Email</label>
             <br></br>
             <div style={{position: "relative",
                          display:"inline-block", 
                          width:"279px",
                          marginBottom:"20px"}}>
               <input placeholder='' 
                required
                id="email"
                onChange={handleChangeEmail} 
                autoFocus
                style={{
                  marginRight:"5px", 
                  width:"100%", 
                  height:"40px", 
                  borderRadius:"2px", 
                  borderColor: "#D9D9D9",
                  borderWidth:"1px",
                  backgroundColor:"#EFEFEF"}} 
                type="text">
              </input>
              {errorState.error.emailError && <label style={{color:"red", marginLeft:"5px"}}>{errorState.error.emailError}</label>}
          </div>
            <br></br>
             <div style={{position: "relative", display:"inline-block", width:"95%", marginBottom: "10px"}}>
               <label style={{marginBottom: "5px", 
                fontFamily: 'Heiti SC, sans-serif',
                fontWeight: "400",
                fontSize:"16px" }}>Password</label>
               <input placeholder='' 
                required
                id="password"
                onChange={handleChangePassword} 
                style={{
                    marginRight:"5px", 
                    width:"279px", 
                    height:"40px", 
                    borderRadius:"2px", 
                    borderColor: "#D9D9D9",
                    borderWidth: "1px",
                    backgroundColor:"#EFEFEF"}} 
                type="password">
              </input>
              {errorState.error.passwordError && <label style={{color:"red", marginLeft:"5px"}}>{errorState.error.passwordError}</label>}
            </div>
            <br></br>
            {customError && <div><label style={{color:"red", marginLeft:"7px"}}>{customError}</label>
            <br></br></div>}
            <div style={{marginLeft:"45px"}}>
            <Button
              color="primary"
              onClick={handleLogin}
              style={{
                marginTop:"20px", 
                width:"187px", 
                backgroundColor:"#197770", 
                borderColor:"#197770"}}
            >
               {isLoading ?  (
                      <>
                      <FontAwesomeIcon icon={faSpinner} spin />
                      <span style={{ marginLeft: '0.5rem' }}>logger ind...</span>
                    </>
                  ) : (
                    'Log in'
                  )}
            </Button>
            </div>
            <div style={{marginLeft: "72px",marginTop: "30px"}}>
              <Link to="/resetpassword" >
                Glemt password?
              </Link>
            </div>
            <div style={{marginLeft: "62px",marginTop: "30px"}}>
              <Link to="/terms" >
                  Vilkår og betingelser?
              </Link>
            </div>
           </div>
        </div>
    );
  }

  return loginRender();
}
