export const CalculateWaitingTimes = (teachers: number, amountOfstudents: number) => {
    const teacherQueues = Array(teachers).fill(0); // Array to track workload (minutes) for each teacher
    const waitingTimes = [] as number[]; // To store waiting times for each student
  
    for(let i=0; i<=amountOfstudents; i++){
      // Find the teacher with the least workload
      const teacherIndex = teacherQueues.indexOf(Math.min(...teacherQueues));
  
      // Assign the current student to this teacher
      const waitTime = teacherQueues[teacherIndex];
      waitingTimes.push(waitTime);
  
      // Increase the workload of this teacher by 20 minutes for the next student
      teacherQueues[teacherIndex] += 20;
    };
  
    return waitingTimes;
  }
  
  // Example Usage
  //const numberOfTeachers = 3;
  //const students = [1, 2, 3, 4, 5, 6];
  
 // const waitingTimes = calculateWaitingTimes(numberOfTeachers, 6);
  //console.log(waitingTimes);



  /*function calculateWaitingTimes(teachers, students) {
    const teacherQueues = Array(teachers).fill(0); // Array to track workload (minutes) for each teacher
    const waitingTimes = []; // To store waiting times for each student
  
    students.forEach((_, index) => {
      // Find the teacher with the least workload
      const teacherIndex = teacherQueues.indexOf(Math.min(...teacherQueues));
  
      // Assign the current student to this teacher
      const waitTime = teacherQueues[teacherIndex];
      waitingTimes.push(waitTime);
  
      // Increase the workload of this teacher by 20 minutes for the next student
      teacherQueues[teacherIndex] += 20;
    });
  
    return waitingTimes;
  }
  
  // Example Usage
  const numberOfTeachers = 2;
  const students = ['Student1', 'Student2', 'Student3', 'Student4', 'Student5'];
  
  const waitingTimes = calculateWaitingTimes(numberOfTeachers, students);
  console.log(waitingTimes);
  // Output: [0, 0, 20, 20, 40]*/
  