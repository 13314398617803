import { Capacitor } from "@capacitor/core";

export interface IConfiguration {
  baseAddress: string;
}

export class Configuration implements IConfiguration {
  public get baseAddress(): string {

    if (process.env.NODE_ENV === "development") {
      return "https://localhost:7162/";
    }

    if(Capacitor.isNativePlatform()){
      return "http://10.0.2.2:5162/";
    }

    return process.env.REACT_APP_API_BASE_URL || "https://ibnamer.dk/";
  }
}
