import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link, Navigate, useNavigate} from "react-router-dom";
import { AuthService, IAuthService } from "./auth-service";
import { INewPasswordRequest } from "./model/dto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useTouchScreenLongPressEffect } from "../touch-screen-long-press/touch-screen-long-press";
import { RefreshButton } from "../refresh-button/refresh-button";
import { getAuthDataFromLocalStorage } from "./model/auth-utils";

export interface NewPassword {}

interface ILoginErrors{
  newPasswordError:string;
  confirmNewPasswordError: string;
  passwordEqualError: string;
}

export function NewPassword(props: NewPassword) {

  const authData = getAuthDataFromLocalStorage();

  const [newPasswordState, setNewPasswordState] = useState({email: authData.email} as INewPasswordRequest);
  const [confirmNewPasswordState, setConfirmNewPasswordState] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorState, setErrorState] = useState({
    error: { } as ILoginErrors
   });

  const isLongPress = useTouchScreenLongPressEffect();

  const navigate = useNavigate();

  const authService: IAuthService = new AuthService();

  async function handleNewPassword() {

    if(hasError()) return;

    setIsLoading(true);

    await authService.newPassword(newPasswordState);

    setIsLoading(false);

    navigate("/login");  
  }

  function handleChangePassword(event: any) {
    setNewPasswordState({...newPasswordState, password: event.target.value});
  }

  function handleChangeComfirmPassword(event: any) {
    setConfirmNewPasswordState(event.target.value);
  }

  const hasError = ()=> { 
 
    const errors = {error: {
      ...errorState.error, 
      newPasswordError: !validatePassword(newPasswordState.password ? newPasswordState.password : "") ? "Adgangskoden er ugyldig. Den skal være mindst 8 tegn lang, indeholde mindst ét stort bogstav, ét tal og ét specialtegn." : "",
      confirmNewPasswordError: !validatePassword(confirmNewPasswordState ? confirmNewPasswordState : "") ? "Adgangskoden er ugyldig. Den skal være mindst 8 tegn lang, indeholde mindst ét stort bogstav, ét tal og ét specialtegn." : "",
      passwordEqualError: (newPasswordState.password !== confirmNewPasswordState) ? "Password er ikke ens i de to felter" : "",
     }};

    setErrorState(errors);

    if(errors.error.newPasswordError || errors.error.confirmNewPasswordError || errors.error.passwordEqualError){
      return true;
    }

    return false;
  }

  function validatePassword(password: string) {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\S]{8,}$/;

    if (passwordRegex.test(password)) {
        return true;
    } else {
        return false;
    }
  }

  function render(){
    if(!authData.accessToken){
      return <Navigate to="/login"  />;
    }

    return (
      <>
       {isLongPress && <div style={{
        position:"absolute", 
        zIndex:"9999999",  
        overflow:"visible",
        marginLeft:"45%", 
        marginTop: "20%", 
        backgroundColor: "white",
        borderRadius:"50%",
        width:"32px",
        height:"32px"}}>
        <RefreshButton showRefreshIcon={isLongPress} />
    </div>}
      <div  style={{display:"table", marginLeft:"5%", width:"80%", marginTop:"50px"}}>
           <label style={{marginBottom: "5px", marginLeft:"4px"}}>Nyt password</label>
           <br></br>
           <div style={{position: "relative", display:"inline-block", width:"95%", marginBottom:"20px"}}>
             <input placeholder='Nyt password' 
              required
              id="email"
              onChange={handleChangePassword} 
              autoFocus
              style={{
                marginLeft:"5px", 
                marginRight:"5px", 
                width:"100%", 
                height:"40px", 
                borderRadius:"6px", 
                borderColor: "#1D5F35"}} 
              type="password">
            </input>
            {errorState.error.newPasswordError && <label style={{color:"red", marginLeft:"5px"}}>{errorState.error.newPasswordError}</label>}
          </div>
      </div>
      <div  style={{display:"table", marginLeft:"5%", width:"80%", marginTop:"10px"}}>
           <label style={{marginBottom: "5px", marginLeft:"4px"}}>Bekræft nyt password</label>
           <br></br>
           <div style={{position: "relative", display:"inline-block", width:"95%", marginBottom:"20px"}}>
             <input placeholder='Bekræft nyt password ' 
              required
              id="email"
              onChange={handleChangeComfirmPassword} 
              autoFocus
              style={{
                marginLeft:"5px", 
                marginRight:"5px", 
                width:"100%", 
                height:"40px", 
                borderRadius:"6px", 
                borderColor: "#1D5F35"}} 
              type="password">
            </input>
            {errorState.error.confirmNewPasswordError && <label style={{color:"red", marginLeft:"5px"}}>{errorState.error.confirmNewPasswordError}</label>}
            <br></br>
            {errorState.error.passwordEqualError && <label style={{color:"red", marginLeft:"5px", marginTop:"15px"}}>{errorState.error.passwordEqualError}</label>}
          </div>
      </div>
      <div>
           <Button
              color="primary"
              onClick={handleNewPassword}
              style={{
                marginTop:"5px", 
                marginLeft:"26px", 
                width:"60%", 
                backgroundColor:"#1D5F35", 
                borderColor:"#1D5F35"}}>
               {isLoading ?  (
                    <>
                    <FontAwesomeIcon icon={faSpinner} spin />
                    <span style={{ marginLeft: '0.5rem' }}>Gemmer...</span>
                  </>
                ) : (
                  'Opret nyt password'
                )}
            </Button>
        </div>
        <div style={{marginLeft: "44px",marginTop: "10px"}}>
            <Link to="/login" >
              Gå til Login
            </Link>
        </div>
      </>
  );
  }

   return render();
}
