import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export interface ICommentMemorizationSessionModal{
  comment: string;
  showCommentModal: boolean;
  closeCommentModal: ()=> void;
}

function CommentMemorizationSessionModal(props: ICommentMemorizationSessionModal) {

    const {comment, showCommentModal, closeCommentModal} = props;

    const handleClose = () => closeCommentModal();

    return (
      <>
        <Modal show={showCommentModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Kommentar til elev</Modal.Title>
          </Modal.Header>
          <Modal.Body>
             {comment}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Luk
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default CommentMemorizationSessionModal;