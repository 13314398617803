export enum SessionStatus {
    Waiting = 1,
    Inprog = 2,
    Completed = 3
}

export enum MemorizationStatus {
  New = 1,
  Completed = 2
}

export enum MemorizationType {
  Hifz = 1,
  Murajara = 2
}

export enum MemorizationChapterOptionStatus {
  New = 1,
  Edit = 2
}

export enum EnvironmentStatus {
  Development = "development",
  AzureDev= "dev",
  AzureTest = "test",
  AzureProduction = "production"
}