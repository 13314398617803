import React, {  useState } from 'react';
import search from '../../image/search.png';
import clear from '../../image/clear.png';
import './search-user.css';
import { IUser } from '../../user/user';
import { AccountService, IAccountService } from '../../services/account-service';

export interface ISearch{
  onSelectUser: (student: IUser) => void;
  onClearSearchValue: () => void;
}

export function SearchUser(props: ISearch) {

const {onSelectUser, onClearSearchValue} = props;

 const [usersState, setUsers] = useState({
    users: [] as IUser[],
 });

 const [searchValueState, setSearchValue] = useState("");

 const accountService: IAccountService = new AccountService();

 const getUsersBySearchValue = async (searchValue: any) => {

    const value = searchValue.target.value; 
    
    if(!value){
      setUsers({ users: [] as IUser[] });
      onClearSearchValue();
    };

    setSearchValue(value);
 };

 const handleSelectUser = (user: IUser) => {
    setSearchValue(user.fullName ? user.fullName : "");
    setUsers({ users: [] });
    onSelectUser(user);
 }

 const handleClearIcon = () => {
  setUsers({ users: [] });
  setSearchValue("");
  onClearSearchValue();
}

const handleKeyPress = async (event: any) => {
  if (event.key === 'Enter') {
    if(searchValueState)
    {
      await accountService
      .getUsersBySearchValue(searchValueState)
      .then((data) => {
          if (data) {
             setUsers({ users: data });
          }
      });
    }
  }
};

const handleClickSearchIcon = async (event: any) => {
    if(searchValueState)
    {
      await accountService
      .getUsersBySearchValue(searchValueState)
      .then((data) => {
          if (data) {
             setUsers({ users: data });
          }
      });
    }
};
  
  return (
    <>
    <div style={{marginLeft:"4px", position: "relative", display:"inline-block", width:"95%"}}>
        <input placeholder='Søg bruger' value={searchValueState} 
        onChange={(value:any) => getUsersBySearchValue(value)} 
        onKeyDown={handleKeyPress}
        style={{
          marginLeft:"5px", 
          marginRight:"5px", 
          width:"100%", 
          height:"40px", 
          borderRadius:"2px", 
          borderWidth:"1px", 
          backgroundColor: "#EFEFEF,", 
          borderColor: "#D9D9D9",
          fontFamily: 'Heiti SC, sans-serif',
          fontWeight: "400",
          fontSize:"16px",
          paddingLeft:"15px"}} 
        type="text"></input>

    </div>
    { usersState.users.length > 0 && <div style={{marginLeft: "5px", borderStyle: "solid", borderRadius: "4px", 
    borderColor: "lightgray", zIndex:"9999", position: "absolute", backgroundColor: "white", width:"95%", maxHeight: "300px", overflowY:"auto"}}>
      {usersState.users.map( user=> {
              return <div key={user.userId} onClick={()=> handleSelectUser(user)} className='searchRow' style={{padding: "10px"}}>
                       <label key={user.studentId}>{user.fullName}</label>
                     </div>
      })}
    </div>}
  </>
  );
}

