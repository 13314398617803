import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link, Navigate, useNavigate} from "react-router-dom";
import { AuthService, IAuthService } from "./auth-service";
import { IResetPasswordRequest } from "./model/dto";
import hifzcenterloginicon from '../../image/hifzcenterloginicon.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useTouchScreenLongPressEffect } from "../touch-screen-long-press/touch-screen-long-press";
import { RefreshButton } from "../refresh-button/refresh-button";

export interface ResetPassword {}

interface ILoginErrors{
  emailError: string;
  passwordError:string;
}

export function ResetPassword(props: ResetPassword) {
  const [resetPasswordState, setResetPasswordState] = useState({} as IResetPasswordRequest);
  const [isLoading, setIsLoading] = useState(false);
  const [customError, setCustomError] = useState("");
  const [errorState, setErrorState] = useState({
    error: { } as ILoginErrors
   });

   const isLongPress = useTouchScreenLongPressEffect();

  const navigate = useNavigate();

  const authService: IAuthService = new AuthService();

  async function handleResetPassword() {

    if(hasError()) return;

    setIsLoading(true);

    await authService.resetPassword(resetPasswordState);

    setIsLoading(false);

    navigate("/login");  
  }

  function handleChangeEmail(event: any) {
    setResetPasswordState({...resetPasswordState, email: event.target.value});
  }

  const hasError = ()=> { 
 
    const errors = {error: {
      ...errorState.error, 
      emailError: (!resetPasswordState.email || !isValidEmail(resetPasswordState.email)) ? "Email is not valid" : "",
     }};

    setErrorState(errors);

    if(errors.error.emailError){
      return true;
    }

    return false;
  }


  const isValidEmail = (email:any) => {
    // Email regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  return (
        <>
         {isLongPress && <div style={{
          position:"absolute", 
          zIndex:"9999999",  
          overflow:"visible",
          marginLeft:"45%", 
          marginTop: "20%", 
          backgroundColor: "white",
          borderRadius:"50%",
          width:"32px",
          height:"32px"}}>
          <RefreshButton showRefreshIcon={isLongPress} />
      </div>}
        {<div>
          <img style={{marginTop:"11px", marginLeft:"10%"}} src={hifzcenterloginicon} alt="Logo" />
        </div>}
        <div  style={{display:"table", marginLeft:"10%", width:"80%", marginTop:"50px"}}>
             <label style={{marginBottom: "5px", marginLeft:"4px"}}>Email</label>
             <br></br>
             <div style={{position: "relative", display:"inline-block", width:"95%", marginBottom:"20px"}}>
               <input placeholder='Email' 
                required
                id="email"
                onChange={handleChangeEmail} 
                autoFocus
                style={{
                  marginLeft:"5px", 
                  marginRight:"5px", 
                  width:"100%", 
                  height:"40px", 
                  borderRadius:"6px", 
                  borderColor: "#1D5F35"}} 
                type="text">
              </input>
              {errorState.error.emailError && <label style={{color:"red", marginLeft:"5px"}}>{errorState.error.emailError}</label>}
            </div>
        </div>
        <div>
             <Button
                color="primary"
                onClick={handleResetPassword}
                style={{
                  marginTop:"5px", 
                  marginLeft:"43px", 
                  width:"40%", 
                  backgroundColor:"#1D5F35", 
                  borderColor:"#1D5F35"}}>
                 {isLoading ?  (
                      <>
                      <FontAwesomeIcon icon={faSpinner} spin />
                      <span style={{ marginLeft: '0.5rem' }}>Gemmer...</span>
                    </>
                  ) : (
                    'Reset password'
                  )}
              </Button>
          </div>
          <div style={{marginLeft: "44px",marginTop: "10px"}}>
              <Link to="/login" >
                Gå til Login
              </Link>
          </div>
        </>
    );
}
