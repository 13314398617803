import React, { useEffect, useState } from 'react'
import AgoraRTC, { IAgoraRTCRemoteUser, ICameraVideoTrack, IMicrophoneAudioTrack} from 'agora-rtc-sdk-ng';
import { VideoPlayer } from './video-player';
import { VideoControl } from './video-controls';
import "./agora-container.css";
import { relative } from 'path';

const client = AgoraRTC.createClient({
  mode:"rtc",
  codec:"vp8" 
});

export interface IUserTrack {
  uid: any;
  videoTrack?: any;
  audioTrack?: any;
  channelName?: any;
}

export interface IVideRoom {
  onLeaveChannel: () => void;
  token: string;
  appId: string;
  channelName: string;
  leaveChannel: boolean;
}

export function VideoRoom(props: IVideRoom) {
  
  const { appId, channelName, token, leaveChannel, onLeaveChannel } = props;
  const [users, setUsers] = useState<IUserTrack[]>([]);
  const [localTracks, setLocalTracks] = useState<[IMicrophoneAudioTrack, ICameraVideoTrack]>([{} as IMicrophoneAudioTrack, {} as ICameraVideoTrack]);
  const [uid, setUid] = useState("");

  const handleUserJoined = async (user: any, mediaType: any) =>{
 
    if(users && users.length <= 2){
        await client.subscribe(user, mediaType);

        if(mediaType === "video"){
          setUsers((prevUsers) => [...prevUsers, {
            uid: user.uid, 
            videoTrack: user.videoTrack, 
            audioTrack: user.audioTrack, 
            channelName: channelName}]);
        }

        if(mediaType === "audio"){
          user.audioTrack.play();
        }
     }
  };

  const handleUserLeft = (user: IUserTrack) =>{
      setUsers((prevUsers) => {
        return prevUsers.filter((User) => User.uid !== user.uid);
      });
  };

  const handleUnpublished = (user: IUserTrack, mediaType: any) =>{
    if (mediaType === "audio") {
      user.audioTrack?.stop();
    }
    if (mediaType === "video") {
      setUsers((prevUsers) => {
        return prevUsers.filter((User) => User.uid !== user.uid);
      });
    }
  };

  useEffect(()=>{

    let init = async () => {
       client.on("user-published", handleUserJoined);
       client.on("user-unpublished", handleUnpublished);
       client.on("user-left", handleUserLeft);

        const uid = await client.join(appId, channelName, token, null);
          
        const tracks = await AgoraRTC.createMicrophoneAndCameraTracks();
      
        const [audioTrack, videoTrack] = tracks;

        videoTrack.setEnabled(false);
        
        setLocalTracks(tracks);

        setUsers((prevUsers) => [...prevUsers, {uid, videoTrack: videoTrack, audioTrack: audioTrack, channelName: channelName}]);
        
        setUid(uid as string);
          
        client.publish(tracks);
    };
 
    init();
  

    //client.on("user-published", handleUserJoined);
    /*client.on("user-left", handleUserLeft)*/

   /* client.join(APP_ID, channelName, tempToken, null)
          .then((uid: any) => 
              Promise.all([AgoraRTC.createMicrophoneAndCameraTracks(), uid])
           )
           .then(([tracks, uid]) => {

              const [audioTrack, videoTrack] = tracks;
              setLocalTracks(tracks);
              setUsers([...users, {uid, videoTrack, audioTrack}]);
              
              client.publish(tracks);
           });*/
    
          /* return () => {

              for(let localTrack of localTracks){
                localTrack.stop();
                localTrack.close();
              }
             // client.off("user-published", handleUserJoined)
              //client.off("user-left", handleUserLeft)
              client.unpublish(localTracks).then(()=>{ client.leave()});
           };*/
        /*return () => {
            if(users.find(x=> x.uid === uid))
            {
                for(let localTrack of localTracks){
                  localTrack.stop();
                  localTrack.close();
                }
                client.off("user-published", handleUserJoined)
                client.off("user-left", handleUserLeft)
                client.unpublish(localTracks).then(()=>{ client.leave()});
            }
         }*/
  }, []);

  const handleLeaveChannel = ()=> {
    onLeaveChannel();
  }

  return(
    <>
     {/*(users.length  === 1 || users.length > 2) && <div style={{display:"flex", justifyContent:"center"}}>
       <div style={{display:"grid" , marginLeft: "50px", gridTemplateColumns: "repeat(2,200px)"}}>
          {users.map((user:IUserTrack) => (
            <VideoPlayer key={user.uid} user={user} style={{width:"200px", height:"200px"}} />
          ))}
       </div>
          </div>*/}
     {users && users.filter(x=>x.channelName === channelName).length  === 1 && 
       <div style={{overflow:"hidden"}}>
          {users.map((user:IUserTrack) => (
            <VideoPlayer key={user.uid} user={user} style={{width:"70vh", height:"15vh"}} />
          ))}
         </div>
      }
      
     {users && users.filter(x=>x.channelName === channelName).length  === 2 &&
            <div style={{position: "relative"}}>
              <div style={{top:"0", overflow:"hidden"}}>
                    <VideoPlayer 
                        key={1} 
                        user={users.find(u=> u.uid !== uid) as IUserTrack}
                        style={{width:"70vh", height:"15vh"}} />         
              </div>
              <div style={{position:"absolute", top:"0", right:"0"}}>
                    <VideoPlayer 
                        key={2} 
                        user={users.find(u=> u.uid === uid) as IUserTrack}
                        style={{width:"100px", height:"100px"}} />
              </div>    
            </div>
      }
     {users && users.length > 0 && <VideoControl 
        tracks={localTracks} 
        onLeaveChannel={handleLeaveChannel} 
        client={client} 
        leaveChannel ={leaveChannel}/>}
     </>
  );

}