import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Dropdown, DropdownButton} from "react-bootstrap";
import './memorization-container.css';
import { IMemorizationChapter } from '../../models/memorization-chapter';
import { MemorizationChapterTable } from './memorization-chapter-table';
import { IMemorization } from '../../models/memorization';
import { useUserContext } from '../../user/user-context';
import { ITeacher } from '../../models/teacher';
import { GetDateByString} from '../../helpers/date-helper';
import { MemorizationChapterOptionStatus, MemorizationType } from '../../enums/status-enums';
import MemorizationChapterOptionModal from './memorization-chapter-option-modal';
import { SortDateDescending } from '../../helpers/sort-helper';

interface IMemorizationHomeWorkEditModalErrors{
  memorizationGradeError: string;
  repititionGradeError: string;
}

export interface IMemorizationHomeWorkEditModal {
   onCompleteMemorizationHomeWork: (memorization: IMemorization) => void;
   onUpdateMemorizationHomeWork: (memorization: IMemorization) => void;
   memorization: IMemorization;
   showModal: boolean;
   onHideModal: () => void;
   memorizations: IMemorization[];
   lastFinishedMemorization: IMemorization;
}

function MemorizationHomeWorkEditModal(props: IMemorizationHomeWorkEditModal) {

   const {showModal, memorization, memorizations, lastFinishedMemorization,
     onCompleteMemorizationHomeWork, onUpdateMemorizationHomeWork, onHideModal} = props;

   const {userContext} = useUserContext();
   
   const [show, setShow] = useState(showModal);

   const [errorState, setErrorState] = useState({
    error: { } as IMemorizationHomeWorkEditModalErrors
   });

   const [selectedMemorizationGrade, setMemorizationGrade] = useState(memorization.memorizationGrade);
   const [selectedRepetitionGrade, setRepetitionGrade] = useState(memorization.repetitionGrade);

   const [memorizationChaptersState, setMemorizationChapters] = useState(memorization.memorizationChapters 
    ? memorization.memorizationChapters : [] as IMemorizationChapter[]);

   useEffect(()=>{
      setShow(showModal);
   },[showModal])

   useEffect(()=>{
    if(memorization.memorizationChapters)
    {
      setMemorizationChapters(memorization.memorizationChapters);
    }
 },[memorization.memorizationChapters])


 useEffect(()=>{
  if(memorization.memorizationGrade){
    setMemorizationGrade(memorization.memorizationGrade.toString());
  }
},[memorization.memorizationGrade])

useEffect(()=>{
  if(memorization.repetitionGrade){
     setRepetitionGrade(memorization.repetitionGrade.toString());
  }
},[memorization.repetitionGrade])

   const handleClose = () => 
   {
      setShow(false);
      setMemorizationChapters([]);
      setMemorizationGrade("");
      setRepetitionGrade("");
      onHideModal();
      setErrorState({error: {} as IMemorizationHomeWorkEditModalErrors});
   };


  const handleChangeMemorizationChapter = (memorizationChapter: IMemorizationChapter)=> {
    setMemorizationChapters(
      memorizationChaptersState.map(mc => 
        mc.chapterId === memorizationChapter.chapterId && mc.memorizationType === memorizationChapter.memorizationType
        ? memorizationChapter 
        : mc)
    )
  }

  const handleCompleteMemorizationHomeWork = () => {
    
    if(checkForErrors()) return;

    const memorizationLocal = {
          memorizationId: memorization.memorizationId,
          memorizationChapters: memorizationChaptersState,
          student: memorization.student,
          teacher: {teacherId: userContext.teacherId} as ITeacher,
          memorizationGrade: selectedMemorizationGrade ? selectedMemorizationGrade : 0,
          repetitionGrade: selectedRepetitionGrade ? selectedRepetitionGrade : 0
    } as IMemorization;

    onCompleteMemorizationHomeWork(memorizationLocal);
    setShow(false);
    setMemorizationGrade("");
    setRepetitionGrade("");
    setErrorState({error: {} as IMemorizationHomeWorkEditModalErrors});
  }

  const handleUpdateMemorizationHomeWork = () => {
    const memorizationLocal = {
          memorizationId: memorization.memorizationId,
          memorizationChapters: memorizationChaptersState,
          student: memorization.student,
          teacher: memorization.teacher
    } as IMemorization;

    onUpdateMemorizationHomeWork(memorizationLocal);
    
    setShow(false);
    setMemorizationGrade("");
    setRepetitionGrade("");
  }

  const handleSelectMemorizationGrade=(e:any)=>{
    setMemorizationGrade(e);
  }

  const handleSelectRepitionGrade=(e:any)=>{
    setRepetitionGrade(e);
  }

  const getGradeName = (selectedElement: string)=> {
    switch(selectedElement){
      case "1":
        return "Perfekt";
      case "2":
        return "Meget godt";
      case "3":
        return "Godt";
      case "4":
        return "Har ikke memoriseret";
    }
  }

  const checkForErrors = ()=> {
    
    const hifzType = hasMemorizationType(MemorizationType.Hifz);
    const murajaraType = hasMemorizationType(MemorizationType.Murajara);

    let errors = {} as IMemorizationHomeWorkEditModalErrors;
    if(!selectedMemorizationGrade && hifzType){
       errors.memorizationGradeError = "Hifd karakter værdi skal være valgt" 
    }
    if(!selectedRepetitionGrade && murajaraType){
      errors.repititionGradeError = "Murajara karakter værdi skal være valgt" 
    }

    if(errors.memorizationGradeError || errors.repititionGradeError){
      setErrorState({
        error:{
          memorizationGradeError: hifzType ? errors.memorizationGradeError : "", 
          repititionGradeError: murajaraType ? errors.repititionGradeError : ""
        }
      })

      return true;
    }
  }

  const hasMemorizationType = (memorizationType: number)=>{
    return memorizationChaptersState.some(mc=> mc.memorizationType === memorizationType);
  }

  const handleUpdateMemorizationChapters = (memorizationChapters: IMemorizationChapter[]) => {
    setMemorizationChapters(memorizationChapters);
  }

  const IsMemorizationOpenForChanges = ()=> {
    if(!memorizations || memorizations.length === 0) return true;

    const lastMemorization = memorizations.sort(SortDateDescending)[0];
    return (lastMemorization.memorizationId === memorization.memorizationId &&
            lastMemorization.completedDate || !memorization.completedDate) ? true : false;
  }

  const isMemorizationOpenForChanges = IsMemorizationOpenForChanges();

  return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Hifd hjemmearbejde</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
         <div>  
              {
                memorization.teacher && memorization.teacher.fullName 
                && <label style={{marginBottom: "20px"}}>Lære: {memorization.teacher.fullName}</label>
              }
              <br></br>
              {
                memorization.completedDate && 
                <label style={{marginBottom: "20px"}}>Dato: {GetDateByString(memorization.completedDate.toString())}
                </label>
                
              }
             {isMemorizationOpenForChanges && <div style={{marginBottom: "20px"}}>
              {
                  <MemorizationChapterOptionModal 
                      memorizationChapters={memorizationChaptersState}
                      onUpdateMemorizationChapters={handleUpdateMemorizationChapters} 
                      memorizationChapterOptionStatus= {MemorizationChapterOptionStatus.Edit} 
                      lastFinishedMemorization= {lastFinishedMemorization}/>
                 }
              </div>
              }
    
          { memorizationChaptersState && <div>
             <MemorizationChapterTable 
                memorizationChapters={memorizationChaptersState}
                memorization= {memorization}
                onChangeMemorizationChapter = {handleChangeMemorizationChapter} />
              </div>}
              <br></br>
              { <div style={{display: "flex" ,marginBottom: "10px"}}>
              <br></br>
            <label style={{marginBottom: "10px"}}>Karakter hifd:</label>
            { <DropdownButton className="custom-dropdown" disabled={!isMemorizationOpenForChanges
              || !hasMemorizationType(MemorizationType.Hifz)} 
            onSelect={handleSelectMemorizationGrade} style={{marginLeft: "44px", marginTop: "-5px"}} 
            key="dropdownkey" 
            id="dropdown-basic-button" title={!selectedMemorizationGrade ? "Hifz grade" : getGradeName(selectedMemorizationGrade)}>
              <Dropdown.Item eventKey="1">Perfekt</Dropdown.Item>
              <Dropdown.Item eventKey="2">Meget godt</Dropdown.Item>
              <Dropdown.Item eventKey="3">Godt</Dropdown.Item>
              <Dropdown.Item eventKey="4">Har ikke memoriseret</Dropdown.Item>
            </DropdownButton>
            }
          </div>}
          {isMemorizationOpenForChanges  && errorState.error.memorizationGradeError &&  <label style={{marginBottom: "10px", color:"red"}}>{errorState.error.memorizationGradeError}</label>}
              <br></br>
            { <div style={{display: "flex" ,marginBottom: "10px"}}>
              <br></br>
            <label style={{marginBottom: "10px"}}>Karakter murajara:</label>
            { <DropdownButton className="custom-dropdown" disabled={!isMemorizationOpenForChanges
            || !hasMemorizationType(MemorizationType.Murajara)} 
            onSelect={handleSelectRepitionGrade} 
            style={{marginLeft: "10px", marginTop: "-5px"}} 
            key="dropdownkey" 
            id="dropdown-basic-button" title={!selectedRepetitionGrade ? "Murajara grade" : getGradeName(selectedRepetitionGrade)}>
              <Dropdown.Item eventKey="1">Perfekt</Dropdown.Item>
              <Dropdown.Item eventKey="2">Meget godt</Dropdown.Item>
              <Dropdown.Item eventKey="3">Godt</Dropdown.Item>
              <Dropdown.Item eventKey="4">Har ikke memoriseret</Dropdown.Item>
            </DropdownButton>
            }
          </div>}
          </div>
          {errorState.error.repititionGradeError &&  <label style={{color:"red"}}>{errorState.error.repititionGradeError}</label>}
          <br></br>
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button style={{backgroundColor:"#1D5F35"}} variant="secondary" onClick={handleClose}>
              Luk
            </Button>
            {isMemorizationOpenForChanges && 
            <Button style={{backgroundColor:"#1D5F35"}} variant="primary" onClick={handleUpdateMemorizationHomeWork}>
              Opdater hjemmearbejde
            </Button>}
             {isMemorizationOpenForChanges  && 
             <Button style={{backgroundColor:"#1D5F35"}} variant="primary" onClick={handleCompleteMemorizationHomeWork}>
              Afslut hjemmearbejde
            </Button>}
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default MemorizationHomeWorkEditModal;