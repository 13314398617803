import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { IMemorizationSession } from '../../models/memorization-session';

export interface IMemorizationSessionStudentModal {
    showModal: boolean;
    onHideModal: () => void;
    memorizationSession: IMemorizationSession;
    onDeleteSession: (memorizationSessionId: string) => void;
}

function MemorizationSessionStudentModal(props: IMemorizationSessionStudentModal) {
 
   const {showModal, memorizationSession, onHideModal, onDeleteSession} = props;

    const onCloseModal = () => {
        onHideModal();
    };

    const handleDeleteSession = ()=> {
      onDeleteSession(memorizationSession.memorizationSessionId);
    }
  
  
    return (
      <>
        <Modal show={showModal} onHide={onCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Hifd session</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
          <label style={{marginBottom: "10px"}}>Navn: {memorizationSession.student.fullName}</label>
          <br></br>
          <label style={{marginBottom: "10px"}}>Telefon: {memorizationSession.student.phone}</label>
          <br></br>
          <label style={{marginBottom: "10px"}}>Elev nummer: {memorizationSession.student.number}</label>
          {<div style={{display: "flex" ,marginBottom: "10px"}}><label>Online</label> <Form>
              <Form.Check 
                type="checkbox"
                id="custom-checkbox"
                disabled={true}
                style={{marginLeft: "5px"}}
                defaultChecked={memorizationSession.online}
              />
            </Form>
            </div>}
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button style={{backgroundColor:"#1D5F35"}} variant="secondary" onClick={onCloseModal}>
              Luk
            </Button>
            <Button style={{backgroundColor:"#1D5F35"}} variant="primary" onClick={handleDeleteSession}>
              Slet Session
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default MemorizationSessionStudentModal;