import React, { useEffect, useState } from 'react';
import { IMemorization } from '../../models/memorization';
import { IStudent } from '../../models/student';
import { ITeacher } from '../../models/teacher';
import { IMemorizationService, MemorizationService } from '../../services/memorization-service';
import { UserHasRoles } from '../../user/role';
import { useUserContext } from '../../user/user-context';
import { SearchStudent } from '../search-student/search-student';
import MemorizationHomeWorkModal from './memorization-homework-modal';
import { MemorizationTable } from './memorization-table';


export function MemorizationContainer() {

  const {userContext} = useUserContext();

  const [memorizationsState, setMemorizationsState] = useState({
    memorizations: [] as IMemorization[],
  });

  const [studentState, setStudentState] = useState({
    student: {} as IStudent,
  });

  const [lastFinishedMemorizationState, setLastFinishedMemorizationState] = useState({
    memorization: {} as IMemorization,
  });

  useEffect(()=>{
    if(!UserHasRoles(userContext,["Administrator","Teacher"])){
      handleSelectStudent({studentId: userContext.studentId } as IStudent);
    }
  },[]);

  const memorizationService: IMemorizationService = new MemorizationService();

  const getMemorizationsByStudent = async (studentId: string) => {
    await memorizationService
      .getMemorizationsByStudentId(studentId)
      .then((data) => {
        if (data) {
          setMemorizationsState({ memorizations: data });
        }
      });
  };

  const handleCreateMemorizationHomeWork = async (memorization: IMemorization) => {
    await memorizationService
      .createMemorizationHomework(memorization)
      .then((data) => {
        if (data) {
          setMemorizationsState({ memorizations: data });
        }
      });
  };

  const handleUpdateMemorizationHomeWork = async (memorization: IMemorization) => {
    await memorizationService
      .updateMemorizationHomework(memorization)
      .then((data) => {
        if (data) {
          setMemorizationsState({ memorizations: data });
        }
      });
  };

  const handleCompleteMemorizationHomeWork = async (memorization: IMemorization) => {
    await memorizationService
      .completeMemorizationHomework(memorization)
      .then((data) => {
        if (data) {
          setMemorizationsState({ memorizations: data });
        }
      });
  };


  const handleSelectStudent = async (student: IStudent) => {
      await getMemorizationsByStudent(student.studentId);
      setStudentState({student});
      getLastFinishedMemorizationByStudentId(student.studentId);
  }

  const handleClearSearchValue = () => {
    setMemorizationsState({ memorizations: [] });
    setStudentState({student: {} as IStudent})
  }

  const hasUncompletedMemorizationHomeWork = ()=> {
      return memorizationsState.memorizations &&
             memorizationsState.memorizations.length > 0 &&
             memorizationsState.memorizations.some(m => !m.completedDate);
  }

  const getLastFinishedMemorizationByStudentId = async (studentId: string) => {
    await memorizationService
      .getLastFinishedMemorizationByStudentId(studentId)
      .then((data) => {
        if (data) {
          setLastFinishedMemorizationState({ memorization: data });
        }
      });
  };

  return (
    <>
     {UserHasRoles(userContext,["Administrator","Teacher"]) &&  <div>
        <SearchStudent onSelectStudent={handleSelectStudent} onClearSearchValue={handleClearSearchValue}/>
      </div>}
      <div style={{marginTop:"10px"}}>
        {studentState.student.studentId && <MemorizationHomeWorkModal 
            student={studentState.student} 
            teacher={{teacherId: userContext.teacherId, fullName: userContext.fullName } as ITeacher}
            onCreateMemorizationHomeWork = {handleCreateMemorizationHomeWork}
            hasUncompletedMemorizationHomeWork = {hasUncompletedMemorizationHomeWork()}
            lastFinishedMemorization={lastFinishedMemorizationState.memorization}/>}
      </div>
      <div style={{marginTop:"10px"}}>
       {memorizationsState.memorizations.length >= 0 && studentState.student.studentId &&
       <MemorizationTable 
        memorizations={memorizationsState.memorizations}
        student={studentState.student && studentState.student.fullName
          ? studentState.student  
          : {studentId: userContext.studentId, 
            fullName: userContext.fullName, 
            number: userContext.number, 
            years: userContext.years,
            phone: userContext.phone} as IStudent} 
        teacher={{teacherId: userContext.teacherId, fullName: userContext.fullName } as ITeacher}
        onCompleteMemorizationHomeWork={handleCompleteMemorizationHomeWork}
        onUpdateMemorizationHomeWork={handleUpdateMemorizationHomeWork} 
        onRefreshStudent={handleSelectStudent}
        lastFinishedMemorization={lastFinishedMemorizationState.memorization}/>
       }
      </div>
    </>
  );
}

