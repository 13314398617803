import React, { useEffect, useState } from 'react';
import { IMemorizationSession } from '../../models/memorization-session';
import { MemorizationSessionService, IMemorizationSessionService } from '../../services/memorization-session-service';
import { useUserContext } from '../../user/user-context';
import BookMemorizationSessionModal from './book-Memorization-session-modal';
import { MemorizationSessionTable } from './memorization-sessions-table';
import { ICompleteMemorizationSessionRequest } from '../../models/complete-memorization-session-request';
import { IMemorizationService, MemorizationService } from '../../services/memorization-service';
import { IMemorization } from '../../models/memorization';
import { ITeacherService, TeacherService } from '../../services/teacher-service';

export function MemorizationSessionContainer() {

  const [memorizationSessionState, setMemorizationSessionsState] = useState({
    memorizationSessions: [] as IMemorizationSession[],
  });

  const [memorizationState, setMemorizationState] = useState({
    memorization: {} as IMemorization,
  });

  const [lastFinishedMemorizationState, setLastFinishedMemorizationState] = useState({
    memorization: {} as IMemorization,
  });

  const [teacherMemorizationSessionState, setTeacherMemorizationSessionsState] = useState({
    teacherMemorizationSessions: [] as IMemorizationSession[],
  });

  const [teacherIsActiveState, setTeacherIsActiveState] = useState(false);

  const [activeTeachersState, setActiveTeachersState] = useState(0);

  const memorizationSessionService: IMemorizationSessionService = new MemorizationSessionService();
  const memorizationService: IMemorizationService = new MemorizationService();
  const teacherService: ITeacherService = new TeacherService();

  const {userContext} = useUserContext();

  useEffect(() => {
    getMemorizationSessions();
    getActiveTeachers();
    isTeacherActive();
  }, []);

  const getMemorizationSessions = async () => {
    await isTeacherActive()
    await getActiveTeachers();

    await memorizationSessionService
      .getMemorizationSessions()
      .then((data) => {
        if (data) {
          setMemorizationSessionsState({ memorizationSessions: data });
        }
      });
  };
  

  const getOngoingMemorizationByStudentId = async (studentId: string) => {
    await memorizationService
      .getOngoingMemorizationByStudentId(studentId)
      .then((data) => {
        if (data) {
          setMemorizationState({ memorization: data });
        }
      });
    
      await getLastFinishedMemorizationByStudentId(studentId)
  };

  const getTeacherMemorizationSessions = async () => {
    await memorizationSessionService
      .getMemorizationSessionsAssignedToTeacher(userContext.teacherId)
      .then((data) => {
        if (data) {
          setTeacherMemorizationSessionsState({ teacherMemorizationSessions: data });
        }
      });
  };

  const handleOnBookSession = async (online:boolean) => {
    await memorizationSessionService
    .bookMemorizationSessionByStudentId(userContext.studentId, online)
    .then((data) => {
      if (data) {
        setMemorizationSessionsState({ memorizationSessions: data });
      }
    });
  }

  const handleStartSession = async (memorizationSessionId: string) => {
    await memorizationSessionService
    .assignMemorizationSessionToTeacher(memorizationSessionId, userContext.teacherId)
    .then((data) => {
      if (data) {
        setMemorizationSessionsState({ memorizationSessions: data });
      }
    });
  }

  const handleAddBackToQueue = async (memorizationSessionId: string, comment: string)=>{
    await memorizationSessionService
    .addMemorizationSessionBackToQueueAsync(memorizationSessionId, comment)
    .then((data) => {
      if (data) {
        setTeacherMemorizationSessionsState({ teacherMemorizationSessions: data });
      }
    });
  }

  const handleCompleteSession = async (completeMemorizationSessionRequest: ICompleteMemorizationSessionRequest, newMemorization: IMemorization) => {
    await memorizationSessionService
    .completeMemorizationSession(completeMemorizationSessionRequest)
    .then((data) => {
      if (data) {
        handleCreateNewMemorizationHomeWork(newMemorization);
        setTeacherMemorizationSessionsState({ teacherMemorizationSessions: data });
        
      }
    });
  }

  const handleDeleteSession = async (memorizationId: string) => {
    await memorizationSessionService
    .deleteMemorizationSession(memorizationId)
    .then((data) => {
      if (data) {
        setMemorizationSessionsState({ memorizationSessions: data });
      }
    });
  }
  
  const studentHasBookedMemorizationSession = () => {
      return memorizationSessionState.memorizationSessions.length > 0 && 
      memorizationSessionState.memorizationSessions.some( ms => ms.student.studentId === userContext.studentId);
  }

  const handleUpdateMemorizationHomeWork = async (memorization: IMemorization) => {
    await memorizationService
      .updateMemorizationHomework(memorization)
      .then((data) => {
        if (data) {
          getMemorizationSessions();
        }
      });
  };

  const handleCreateNewMemorizationHomeWork = async (memorization: IMemorization) => {
    await memorizationService
      .createMemorizationHomework(memorization)
      .then((data) => {
        if (data) {
        }
      });
  };

  const handleTeacherIsActive = async ()=>{
    await teacherService
    .updateTeacherIsActive(userContext.teacherId)
    .then((data) => {
        setTeacherIsActiveState(data);
        getActiveTeachers();
    });
  }

  const getActiveTeachers = async ()=>{
    await teacherService
    .getActiveTeachers()
    .then((data) => {
        setActiveTeachersState(data);
    });
  }

  const isTeacherActive = async ()=>{
    await teacherService
    .isTeacherActive(userContext.teacherId)
    .then((data) => {
        setTeacherIsActiveState(data);
    });
  }

  const getLastFinishedMemorizationByStudentId = async (studentId: string) => {
    await memorizationService
      .getLastFinishedMemorizationByStudentId(studentId)
      .then((data) => {
        if (data) {
          setLastFinishedMemorizationState({ memorization: data });
        }
      });
  };

  return (
    <>
    <BookMemorizationSessionModal onBookSession={handleOnBookSession}
      studentHasBookedMemorizationSession = {studentHasBookedMemorizationSession()}/>
    <div>
       <MemorizationSessionTable memorizationSessions={memorizationSessionState.memorizationSessions}
        teacherMemorizationSessions={teacherMemorizationSessionState.teacherMemorizationSessions}
        memorization={memorizationState.memorization}
        lastFinishedMemorization={lastFinishedMemorizationState.memorization}
        onStartSession={handleStartSession} 
        onAddBackToQueue={handleAddBackToQueue} 
        onCompleteSession={handleCompleteSession}
        onDeleteSession={handleDeleteSession}
        onTabStudentTabMemorizationSession= {getMemorizationSessions}
        onTabTeacherTabMemorizationSession= {getTeacherMemorizationSessions}
        onClickMemorizationRowTeacher ={getOngoingMemorizationByStudentId}
        onUpdateMemorizationHomeWork={handleUpdateMemorizationHomeWork}
        onTeacherIsActive={handleTeacherIsActive}
        teacherIsActive={teacherIsActiveState}
        activeTeachers={activeTeachersState}/>
    </div>
  </>
  );
}

