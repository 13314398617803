import './App.css';
import { BrowserRouter, Routes,  Route, Navigate, useLocation } from "react-router-dom";
import { BasePageLayout } from './base-page-layout';
import { MemorizationSessionContainer } from './components/memorization-session/memorization-session-container';
import { MemorizationContainer } from './components/memorization/memorization-container';
import { AuthWrapper } from './components/auth/auth-wrapper';
import { Login } from './components/auth/login';
import { Register } from './components/auth/register';
import { ForgotPassword } from './components/auth/forgot-password';
import { ResetPassword } from './components/auth/reset-password';
import { AuthContainer } from './components/auth/auth-container';
import { Logout } from './components/auth/logout';
import { StudentAbsenceContainer } from './components/absence/student-absence-container';
import { UserProfileContainer } from './components/administration/user-profile/user-profile-container';
import { EditUserProfileContainer} from './components/administration/edit-user-profile/edit-user-profile-container';
import { StudentsAbsenceContainer } from './components/absence/students-absence-container';
import { RegisterPaymentContainer } from './components/payment/register-payment-container';
import { ReportPaymentContainer } from './components/payment/report-payment-container';
import { Term } from './components/terms/terms';
import { useEffect, useState } from 'react';
import { NewPassword } from './components/auth/new-password';
import { FeatureService, IFeatureService } from './services/feature-service';
import { GetEnvironmentSessionStorage, IsDevelopmentEnvironment, SetEnvironmentSessionStorage } from './feature-toggle/feature-toggle-helper';

function App() {

  return (
    <BrowserRouter>
           <AppContent/>
    </BrowserRouter>
  );
}

function AppContent() {
  const location = useLocation();

  const [environmentState, setEnvironmentState] = useState("");

  useEffect(() => {
      getEnvironment();
  }, []);

  useEffect(() => {
      window.history.pushState(null, '', window.location.href);
  }, [location]);

  const featureService: IFeatureService = new FeatureService();

  const getEnvironment = async () => {

    const environment = GetEnvironmentSessionStorage();

    if(environment){
      setEnvironmentState(environment);
      return;
    }
    else{
      await featureService
        .getEnvironment()
        .then((data) => {
          if (data) {
            setEnvironmentState(data);
            SetEnvironmentSessionStorage(data);
          }
        });
    }
  };

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/newpassword" element={<NewPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/terms" element={<Term />} />
      <Route element={<AuthContainer />}>
        <Route element={<BasePageLayout />}>
          {IsDevelopmentEnvironment(environmentState) && <Route path="/" element={<MemorizationSessionContainer />} />}
          {IsDevelopmentEnvironment(environmentState)
           ? <Route path="/memorization" element={<MemorizationContainer />} />
           : <Route path="/" element={<MemorizationContainer />} />}
          <Route path="/studentabsence" element={<StudentAbsenceContainer />} />
          <Route path="/studentsabsence" element={<StudentsAbsenceContainer />} />
          <Route path="/registerpayment" element={<RegisterPaymentContainer />} />
          <Route path="/createprofile" element={<UserProfileContainer />} />
          <Route path="/editprofile" element={<EditUserProfileContainer />} />
          <Route path="/reportpayment" element={<ReportPaymentContainer />} />
        </Route>
        <Route element={<AuthWrapper />}>
          <Route path="/logout" element={<Logout />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
