import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import { IMemorization } from "../../models/memorization";
import MemorizationHomeWorkEditModal from "./memorization-homework-edit-modal";
import { IStudent } from "../../models/student";
import { ITeacher } from "../../models/teacher";
import { SortDateDescending } from "../../helpers/sort-helper";
import { GetDateByString } from "../../helpers/date-helper";
import { UserHasRoles } from "../../user/role";
import { useUserContext } from "../../user/user-context";
import MemorizationHomeWorkViewModal from "./memorization-homework-view-modal";
import { MemorizationType } from "../../enums/status-enums";
import { Button,  } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightRotate } from '@fortawesome/free-solid-svg-icons';
import "./memorization-table.css";

export interface IMemorizationTable{
  memorizations: IMemorization[];
  student: IStudent;
  teacher: ITeacher;
  onCompleteMemorizationHomeWork: (memorization: IMemorization)=> void;
  onUpdateMemorizationHomeWork: (memorization: IMemorization)=> void;
  onRefreshStudent: (student: IStudent) => void;
  lastFinishedMemorization: IMemorization;
}

export function MemorizationTable(props: IMemorizationTable){

  const {memorizations, student, lastFinishedMemorization, onCompleteMemorizationHomeWork, onUpdateMemorizationHomeWork, onRefreshStudent} = props;

  const [openModal, setOpenModal] = useState(false);

  const [memorizationRow, setMemorizationRow] = useState({
    memorization: {} as IMemorization
  });

  const {userContext} = useUserContext();

  const rowClick = (memorizationId: string) => {
     setOpenModal(true);
     setMemorizationRow({memorization: memorizations.filter(m=> m.memorizationId === memorizationId)[0]});
  }

  const handleOnHideModal = () => {
    setOpenModal(false);
    setMemorizationRow({memorization:{} as IMemorization});
  }

  const handleCompleteMemorizationHomeWork = (memorization: IMemorization)=> {
    onCompleteMemorizationHomeWork(memorization);
    setOpenModal(false);
    setMemorizationRow({memorization:{} as IMemorization});
  }

  const handleUpdateMemorizationHomeWork = (memorization: IMemorization)=> {
    onUpdateMemorizationHomeWork(memorization);
    setOpenModal(false);
    setMemorizationRow({memorization:{} as IMemorization});
  }

  const handleRefreshStudent = ()=>{
      onRefreshStudent(student);
  }

  return (
    <>
        {(UserHasRoles(userContext,["Administrator","Teacher"]) && userContext.studentId !== student.studentId) 
           ? <MemorizationHomeWorkEditModal 
            memorization= {memorizationRow.memorization}
            memorizations={memorizations}
            showModal={openModal} 
            onHideModal={handleOnHideModal} 
            onCompleteMemorizationHomeWork= {handleCompleteMemorizationHomeWork}
            onUpdateMemorizationHomeWork= {handleUpdateMemorizationHomeWork}
            lastFinishedMemorization = {lastFinishedMemorization}/>
           :
           <MemorizationHomeWorkViewModal 
            memorization= {memorizationRow.memorization}
            showModal={openModal} 
            onHideModal={handleOnHideModal} 
            />
          }
         {<label style={{
            marginTop:"15px",
            marginBottom: "10px",  
            marginLeft: "16px", 
            fontFamily: 'Heiti SC, sans-serif',
            fontWeight: "500",
            fontSize:"18px",
            color:"#000"}}>Elev information</label>}
        {
          <div style={{
              display:"flex", 
              flexDirection: "row",
              width:"95%",
              height:"92px",
              marginLeft:"12px",
              marginBottom:"20px"}}>
            <div style={{
              flex:"1", 
              borderWidth:"1px", 
              backgroundColor: "#EFEFEF", 
              borderColor: "#D9D9D9",
              borderRadius:"4px"}}>
              {student && <label style={{
                marginBottom: "5px", 
                marginLeft: "9px", 
                fontFamily: 'Heiti SC, sans-serif',
                fontSize:"13px",
                }}>Elev: {student.fullName}</label>}
              <br></br>
              {student && <label style={{
                marginBottom: "5px", 
                marginLeft: "10px", 
                fontFamily: 'Heiti SC, sans-serif',
                fontSize:"13px"}}>Elev nummer: {student.number}</label>}
              <br></br>
              {student && <label style={{
                marginBottom: "15px",  
                marginLeft: "10px", 
                fontFamily: 'Heiti SC, sans-serif',
                fontSize:"13px"}}>Studie program {student.years}</label>}
            </div>
          {/* <div style={{marginTop:"50px", marginRight:"10px"}}>
              <Button style={{backgroundColor:"#1D5F35"}} onClick={handleRefreshStudent}>
                  <FontAwesomeIcon icon={faArrowRightRotate} />
              </Button>
              </div>*/}
          </div>
        }
        {memorizations.length >= 0 &&  
        <div style={{overflowY: "auto", height:"400px", marginLeft:"11px", width:"97%"}}>
        <Table striped bordered hover>
            <thead style={{ 
              position: "sticky", 
              top: 0, 
              background:"#197770", 
              color:"white", 
              fontFamily:"sans-serif",
              fontSize:"15px"}}>
              <tr>
                <th style={{ background:"#197770", color:"white", fontSize:"14px", fontFamily: 'Heiti SC, sans-serif'}}>Memorering</th>
                <th style={{ background:"#197770", color:"white", fontSize:"14px", fontFamily: 'Heiti SC, sans-serif'}}>Repetition</th>
                <th style={{ background:"#197770", color:"white", fontSize:"14px", fontFamily: 'Heiti SC, sans-serif'}}>Lærer</th>
                <th style={{ background:"#197770", color:"white", fontSize:"14px", fontFamily: 'Heiti SC, sans-serif'}}>Dato</th>
              </tr>
            </thead>
            <tbody style={{height:"10px !important",  fontFamily: 'Heiti SC, sans-serif', fontSize:"12px"}}>
            {memorizations.sort(SortDateDescending).map((memorization :IMemorization, index) => (
                      <tr  onClick={()=> rowClick(memorization.memorizationId)} key={index}>
                          <td>
                            {memorization.memorizationChapters.
                            filter(mc=> mc.memorizationType === MemorizationType.Hifz).
                            map(mc=> `${mc.name} (${mc.nameArabic})`).join(' , ')}
                          </td>
                          <td>
                            {memorization.memorizationChapters.
                            filter(mc=> mc.memorizationType === MemorizationType.Murajara).
                            map(mc=> `${mc.name} (${mc.nameArabic})`).join(' , ')}
                          </td>
                         {memorization.teacher && memorization.teacher.fullName ?  <td>
                            {memorization.teacher.fullName}
                          </td> : 
                          <td>
                          {}
                        </td>}
                         {memorization.completedDate ? <td>
                            {GetDateByString(memorization.completedDate.toString())}
                          </td> :
                          <td>
                          {}
                          </td>
                          }
                      </tr>
                    ))}
            </tbody>
          </Table>
          </div>
         }    
    </>
  );
}

