import React, { useEffect, useRef, useState } from 'react'
import AgoraRTC, { IAgoraRTCClient, ICameraVideoTrack, IMicrophoneAudioTrack } from 'agora-rtc-sdk-ng';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash, faVideoSlash, faVideo, faPhone} from '@fortawesome/free-solid-svg-icons';

export interface IVideoControl{
  tracks: [IMicrophoneAudioTrack, ICameraVideoTrack];
  onLeaveChannel: () => void;
  client: IAgoraRTCClient;
  leaveChannel: boolean;
}

export function VideoControl(props: IVideoControl) {

  const { tracks, client, leaveChannel,  onLeaveChannel } = props;
  const [trackState, setTrackState] = useState({ video: false, audio: true });

  useEffect(()=>{
    const leaveCh = async ()=>{
      if(leaveChannel){
        await handleLeaveChannel();
      }
    }

    leaveCh();
  }, [leaveChannel])

  const mute = async (type: "audio" | "video") => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    }
  };

  const handleLeaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    // we close the tracks to perform cleanup
    tracks[0].close();
    tracks[1].close();

    onLeaveChannel();
  };
  
  return(
    <div style={{
          display:"flex", 
          width:"100%", 
          height: "40px", 
          justifyContent:"flex-end",
          paddingTop:"8px",
          paddingRight: "10px"
          }}>
        <div style={{marginLeft:"80px"}}>
          <FontAwesomeIcon size="1x" onClick={() => mute("audio")}  
            icon={trackState.audio ?  faMicrophone : faMicrophoneSlash}/>   
        </div>
        <div style={{marginLeft:"20px"}}> 
          <FontAwesomeIcon size="1x" onClick={() => mute("video")}  
            icon={trackState.video ? faVideo : faVideoSlash}/>   
        </div>
        <div style={{marginLeft:"20px"}}>
          <FontAwesomeIcon size="1x" onClick={() => handleLeaveChannel()}  
              icon={faPhone}/> 
        </div>
    </div>
  );

}