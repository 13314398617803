import { IFeatureToggle } from "../feature-toggle/feature-toggle";
import { IFeatureToggleResponse } from "../feature-toggle/feature-toggle-response";
import { BaseApiService, IBaseApiService } from "./base-service";

const url = `api/Feature/`;
const getFeatureToggles = "GetFeatureToggles";
const getEnvironment = "GetEnvironment";

export interface IFeatureService {
  getFeatureToggles(): Promise<IFeatureToggleResponse>;
  getEnvironment(): Promise<string>;
}

export class FeatureService implements IFeatureService {
  private readonly baseService: IBaseApiService;
  constructor() {
    this.baseService = new BaseApiService();
  }
 
  public async getFeatureToggles(): Promise<IFeatureToggleResponse> {
    try {
      return await this.baseService.authGet<IFeatureToggleResponse>(
        `${url}${getFeatureToggles}`
      );
    } catch (error: any) {
      return error;
    }
  }

  public async getEnvironment(): Promise<string> {
    try {
      return await this.baseService.authGet<string>(
        `${url}${getEnvironment}`
      );
    } catch (error: any) {
      return error;
    }
  }

}
