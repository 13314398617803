import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Dropdown, DropdownButton} from "react-bootstrap";
import './memorization-container.css';
import { IMemorizationChapter } from '../../models/memorization-chapter';
import { IMemorization } from '../../models/memorization';
import { MemorizationChapterViewTable } from './memorization-chapter-view-table';



export interface IMemorizationHomeWorkViewModal {
   memorization: IMemorization;
   showModal: boolean;
   onHideModal: () => void;
}

function MemorizationHomeWorkViewModal(props: IMemorizationHomeWorkViewModal) {

   const {showModal, memorization,  onHideModal} = props;
   
   const [show, setShow] = useState(showModal);
   const [selectedMemorizationGrade, setMemorizationGrade] = useState(memorization.memorizationGrade);
   const [selectedRepetitionGrade, setRepetitionGrade] = useState(memorization.repetitionGrade);

   const [memorizationChaptersState, setMemorizationChapters] = useState(memorization.memorizationChapters 
    ? memorization.memorizationChapters : [] as IMemorizationChapter[]);

   useEffect(()=>{
      setShow(showModal);
   },[showModal])

   useEffect(()=>{
    if(memorization.memorizationChapters)
    {
      setMemorizationChapters(memorization.memorizationChapters);
    }
 },[memorization.memorizationChapters])

 useEffect(()=>{
  if(memorization.memorizationGrade){
    setMemorizationGrade(memorization.memorizationGrade.toString());
  }
},[memorization.memorizationGrade])

useEffect(()=>{
  if(memorization.repetitionGrade){
     setRepetitionGrade(memorization.repetitionGrade.toString());
  }
},[memorization.repetitionGrade])


   const handleClose = () => 
   {
      setShow(false);
      setMemorizationChapters([]);
      onHideModal();
   };

   const getGradeName = (selectedElement: string)=> {
    switch(selectedElement){
      case "1":
        return "Perfect";
      case "2":
        return "Very good";
      case "3":
        return "Good";
      case "4":
        return "Have not memorized";
    }
  }

  return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Hifd homework</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div>
         <div>  
              {
                memorization.teacher && <label style={{marginBottom: "20px"}}>Lære: {memorization.teacher.fullName}</label>
              }
    
          { memorizationChaptersState && <div>
             <MemorizationChapterViewTable 
                memorizationChapters={memorizationChaptersState}
                />
              </div>}
              <br></br>
              { <div style={{display: "flex" ,marginBottom: "10px"}}>
              <br></br>
            <label style={{marginBottom: "10px"}}>Grade hifz:</label>
            { <DropdownButton disabled={true} className="custom-dropdown"  style={{marginLeft: "32px", marginTop: "-5px"}} key="dropdownkey" 
            id="dropdown-basic-button" title={!selectedMemorizationGrade ? "Hifz grade" : getGradeName(selectedMemorizationGrade)}>
              <Dropdown.Item eventKey="1">Perfekt</Dropdown.Item>
              <Dropdown.Item eventKey="2">Meget godt</Dropdown.Item>
              <Dropdown.Item eventKey="3">Godt</Dropdown.Item>
              <Dropdown.Item eventKey="4">Har ikke memoriseret</Dropdown.Item>
            </DropdownButton>
            }
          </div>}
              <br></br>
            { <div style={{display: "flex" ,marginBottom: "10px"}}>
              <br></br>
            <label style={{marginBottom: "10px"}}>Karakter murajara:</label>
            { <DropdownButton disabled={true} className="custom-dropdown"  style={{marginLeft: "10px", marginTop: "-5px"}} key="dropdownkey" 
            id="dropdown-basic-button" title={!selectedRepetitionGrade ? "Muraja grade" : getGradeName(selectedRepetitionGrade)}>
              <Dropdown.Item eventKey="1">Perfekt</Dropdown.Item>
              <Dropdown.Item eventKey="2">Meget godt</Dropdown.Item>
              <Dropdown.Item eventKey="3">Godt</Dropdown.Item>
              <Dropdown.Item eventKey="4">Har ikke memoriseret</Dropdown.Item>
            </DropdownButton>
            }
          </div>}
          </div>
      
          <br></br>
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button style={{backgroundColor:"#1D5F35"}} variant="secondary" onClick={handleClose}>
              Luk
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default MemorizationHomeWorkViewModal;