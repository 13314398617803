import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { IMemorizationChapter } from '../../models/memorization-chapter';

export interface IMemorizationChapterModal {
    showModal: boolean;
    onHideModal: () => void;
    memorizationChapter: IMemorizationChapter;
    onChangeMemorizationChapter: (memorizationChapter: IMemorizationChapter)=> void;
}

function MemorizationChapterModal(props: IMemorizationChapterModal) {
 
    const {showModal, onHideModal, memorizationChapter, onChangeMemorizationChapter} = props;

    const [fromAyahState, setFromAyah] = useState(memorizationChapter.fromAyah);
    const [toAyahState, setToAyah] = useState(memorizationChapter.toAyah);

    useEffect(() => {
      setFromAyah(memorizationChapter.fromAyah);
    }, [memorizationChapter.fromAyah, memorizationChapter.memorizationType]);

    useEffect(() => {
      setToAyah(memorizationChapter.toAyah);
    }, [memorizationChapter.toAyah, memorizationChapter.memorizationType]);

    const handleFromAyahChange = (e: any) => {
      const fromAyahLocal = e.target.value as number;
      setFromAyah(fromAyahLocal);
    }

    const handleToAyahChange = (e: any) => {
      const toAyahLocal = e.target.value as number;
      setToAyah(toAyahLocal);
    }

    const onCloseModal = () => {
        onHideModal();
    };

    const handleChangeMemorizationChapter=()=>{
       const memorizationChapterLocal = {
          ...memorizationChapter, 
          fromAyah: Number(fromAyahState), 
          toAyah: Number(toAyahState)};
       onChangeMemorizationChapter(memorizationChapterLocal);
       onHideModal();
    }

    return (
      <>
        <Modal show={showModal} onHide={onCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Surah</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div style={{display:"flex", marginBottom: "10px"}}>
                 <label style={{marginBottom: "15px"}}>Fra ayah: </label>
                 <input value={fromAyahState}  onChange={handleFromAyahChange} type="number" min="1" max="99" className="form-control"
                    style={{marginLeft:"20px", width:"20%", height:"30px", borderRadius:"6px", borderColor: "#0d6efd"}}>
                 </input>
              </div>
              <div style={{display:"flex"}}>
                 <label style={{marginBottom: "10px"}}>Til ayah: </label>
                 <input value={toAyahState} onChange={handleToAyahChange} type="number" min="1" max="99" className="form-control"
                    style={{marginLeft:"26px", width:"20%", height:"30px", borderRadius:"6px", borderColor: "#0d6efd"}}>
                 </input>
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button style={{backgroundColor:"#1D5F35"}} variant="secondary" onClick={onCloseModal}>
              Luk
            </Button>
      
            <Button style={{backgroundColor:"#1D5F35"}} variant="primary" onClick={handleChangeMemorizationChapter}>
              Updater surah
            </Button>
            
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default MemorizationChapterModal;