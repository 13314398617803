import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export interface IBookMemorizationSessionModal{
  openDialog: boolean;
  closeDialog: () => void;
  onAddComment: (comment: string)=> void;
}

function AddCommentMemorizationSessionModal(props: IBookMemorizationSessionModal) {

    const {onAddComment, closeDialog, openDialog} = props;

    const [commentState, setComment] = useState("");
  
    const handleClose = () => {
      setComment("");
      closeDialog();
    }

    const handleChangeComment = (event: any) => {
      setComment(event.target.value );
    }

    const handleAddComment = () => {
      onAddComment(commentState)
      setComment("");
    }
  
    return (
      <>
        <Modal show={openDialog} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Tilføj kommentar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div style={{position: "relative", display:"inline-block", width:"95%", marginBottom:"20px"}}>
                  <label style={{marginBottom: "5px", marginLeft:"4px"}}>Kommentar</label>
                  <input placeholder='Kommentar' 
                    required
                    id="comment"
                    onChange={handleChangeComment} 
                    value={commentState}
                    style={{
                      marginLeft:"5px", 
                      marginRight:"5px", 
                      width:"100%", 
                      height:"40px", 
                      borderRadius:"6px", 
                    }} 
                    type="text">
                  </input>
                </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Luk
            </Button>
            <Button variant="primary" onClick={handleAddComment}>
              Opret kommentar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default AddCommentMemorizationSessionModal;