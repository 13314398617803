import { ITeacher } from "../models/teacher";
import { BaseApiService, IBaseApiService } from "./base-service";

const url = `api/Teacher/`;
const getTeachersBySearchValue = "GetTeachersBySearchValue";
const getActiveTeachers = "GetActiveTeachers";
const updateTeacherIsActive = "UpdateTeacherIsActive";
const isTeacherActive = "IsTeacherActive";

export interface ITeacherService {
    getTeachersBySearchValue(searchValue: string): Promise<ITeacher[]>;
    getActiveTeachers(): Promise<number>;
    updateTeacherIsActive(teacherId: string): Promise<boolean>;
    isTeacherActive(teacherId: string): Promise<boolean>;
}

export class TeacherService implements ITeacherService {
  private readonly baseService: IBaseApiService;
  constructor() {
    this.baseService = new BaseApiService();
  }
 
  public async getTeachersBySearchValue(searchValue: string): Promise<ITeacher[]> {
    try {
      return await this.baseService.authGet<ITeacher[]>(
        `${url}${getTeachersBySearchValue}/${searchValue}`
      );
    } catch (error: any) {
      return error;
    }
  }

  public async getActiveTeachers(): Promise<number> {
    try {
      return await this.baseService.authGet<number>(
        `${url}${getActiveTeachers}`
      );
    } catch (error: any) {
      return error;
    }
  }

  public async isTeacherActive(teacherId: string): Promise<boolean> {
    try {
      return await this.baseService.authGet<boolean>(
        `${url}${isTeacherActive}/${teacherId}`
      );
    } catch (error: any) {
      return error;
    }
  }

  public async updateTeacherIsActive(teacherId: string): Promise<boolean> {
    try {
      return await this.baseService.authGet<boolean>(
        `${url}${updateTeacherIsActive}/${teacherId}`
      );
    } catch (error: any) {
      return error;
    }
  }

}
