import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import { IPayment } from "../../models/payment";
import { GetCurrentDate, GetDateString, GetMonthNameFromDateString, GetYearFromDateString } from "../../helpers/date-helper";
import { Button } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { IPaymentRequest } from "../../models/payment-request";
import { CreateExcelFile } from "../../excel/excel-download";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface IReportPaymentTableErrors{
  monthDateError: string;
}
export interface IReportPaymentTable{
  payments: IPayment[];
  onSearchPayments: (payment: IPaymentRequest) => void;
  isLoading: boolean;
}

export function ReportPaymentTable(props: IReportPaymentTable){

  const {payments, isLoading, onSearchPayments} = props;

  const [selectedMonthDate, setSelectedMonthDate] = useState<Date>(GetCurrentDate());

  const [rowsState, setRowsState] = useState({
    rows: [] as any[]
  });

  const [studentMonthState, setStudentMonthState] = useState({
    studentMonth: [] as any[],
  });

  const handleMonthDateChange = (date: Date) => {
    setSelectedMonthDate(date);
  };

  useEffect(()=>{
    setRowsState({rows: rows()})
  },[payments]);

  const handleDownloadExcel = ()=> {
    CreateExcelFile(studentMonthState.studentMonth);
  }

  const handleSearchPayments = ()=>{
    
    if(hasError()) return;

    onSearchPayments({
      date:  GetDateString(selectedMonthDate)
    } as IPaymentRequest);
  }

  const [errorState, setErrorState] = useState({
    error: { } as IReportPaymentTableErrors
   });


  const hasError = ()=> {

    const errors = {error: {
      ...errorState.error, 
        monthDateError: !selectedMonthDate ? "Month is required" : ""
     }};
  
    setErrorState(errors);
  
    if(errors.error.monthDateError){
      return true;
    }
  
    return false;
  }

  const rows = ()=>{
    let rows = [] as any[];

    if(!payments || payments.length === 0) return rows;

    let studentMonth: any[] = [];

    {payments.length > 0 && payments?.forEach((payment:IPayment, index: number)=>{
        rows.push(<tr key={index}>
          <td>
            {payment.student.fullName}
          </td>
          <td>
            {payment.student.number}
          </td>
          <td>
            {selectedMonthDate && GetYearFromDateString(selectedMonthDate.toString())}
          </td>
          <td>
            {selectedMonthDate && GetMonthNameFromDateString(selectedMonthDate.toString())}
          </td>
          <td style={{backgroundColor: payment.date ? "green" : "yellow"}}>
            {payment.date ? "yes" : "no"}
          </td>
      </tr>);

          studentMonth.push({
              Name: payment.student.fullName,
              Number: payment.student.number,
              Year: selectedMonthDate && GetYearFromDateString(selectedMonthDate.toString()),
              Month: selectedMonthDate && GetMonthNameFromDateString(selectedMonthDate.toString()),
              Payed: payment.date ? "yes" : "no"
          });
    });}

    setStudentMonthState({studentMonth})
    
    return rows;
  }

  return (
    <>
    {<p style={{marginLeft:"10px"}}><b>Betalings rapport</b></p>}
    {
          <div style={{marginTop:"15px"}}>
            <div style={{display:"flex"}}>
                <div style={{width: "130px", marginLeft:"10px", marginBottom: "5px"}}>
                  <DatePicker
                      key="1"
                      title="Startdate"
                      selected={selectedMonthDate}
                      onChange={handleMonthDateChange}
                      onSelect={handleMonthDateChange}
                      showMonthYearPicker
                      dateFormat="MM/yyyy"
                      className="form-control"
                    />
                </div>
                <div style={{marginLeft:"10px"}}>
                  <Button style={{backgroundColor:"#1D5F35", width: isLoading ? '140px' : 'auto'}} variant="secondary" onClick={handleSearchPayments}>
                    {isLoading ?  (
                        <>
                        <FontAwesomeIcon icon={faSpinner} spin />
                        <span style={{ marginLeft: '0.5rem' }}>Søger...</span>
                      </>
                    ) : (
                      'Search'
                    )}
                  </Button>
                </div>
            </div>
            {errorState.error.monthDateError && <label style={{color:"red", marginLeft:"12px", marginBottom:"10px"}}>{errorState.error.monthDateError}</label>}
            <div style={{marginLeft:"11px", marginBottom:"20px", marginTop:"20px"}}>
           <Button style={{backgroundColor:"#1D5F35"}} variant="secondary" onClick={handleDownloadExcel}>
              Download som excel
            </Button>
        </div>
        </div>
        }
        {payments  &&  
        <div style={{overflowY: "auto", height:"400px", marginTop:"30px"}}>
        <Table striped bordered hover>
            <thead style={{ 
              position: "sticky", 
              top: 0, 
              background:"#135128", 
              color:"white", 
              fontFamily:"sans-serif",
              fontSize:"15px"}}>
              <tr>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Navn</th>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Elev nummer</th>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>År</th>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Måned</th>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Betalt</th>
              </tr>
            </thead>
            <tbody style={{height:"10px !important", fontFamily:"sans-serif", fontSize:"13px"}}>
            {rowsState.rows}
            </tbody>
          </Table>
          </div>
         }    
    </>
  );
}

