import { useCallback, useState } from 'react';
import './header.css';
import { Link, useNavigate } from "react-router-dom";
import { Dropdown} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { UserHasRoles } from '../../user/role';
import { useUserContext } from '../../user/user-context';
import { useFeatureToggleContext } from '../../feature-toggle/feature-toggle-context';
import { IsDevEnvironment, IsStorageEnvDev } from '../../feature-toggle/feature-toggle-helper';
import vectorIcon from '../../image/Vector.png';
import menuLogIcon from '../../image/menulogo.png';

export function Header() {

  const {userContext} = useUserContext();

  const [iconClicked, setIconClicked] = useState(false);

  const [title, setTitle] = useState(IsStorageEnvDev() ? "Sessioner" : "Lektie");

  const {featureKeyValuePairsContext} = useFeatureToggleContext();

  const navigate = useNavigate();

  const handleIconClick = () => {
    setIconClicked(!iconClicked);
  };

  const CustomToggle = useCallback(React.forwardRef<HTMLAnchorElement, any>((props, ref) => (
    <a
      href="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        handleIconClick();
        props.onClick(e);
      }}
      className={`custom-toggle ${iconClicked ? 'white' : 'black'}`}
    >
      {<img
        src={vectorIcon}
        alt="Custom Icon"
       
      />}
     
    </a>
  )), []);

  const handleClick = (event:any) => {
    event.preventDefault();
    // handle click event here
  };

  const handleTitle = (title:string, href:string) => {
    setTitle(title);
    navigate(href);
  };

  const vectorLinks = ()=>{
    return <div style={{display:"flex"}}>
            <div style={{marginLeft:"10px", position:"absolute"}}>
              <img
                    src={menuLogIcon}
                    alt="Custom Icon"/>
            </div>
            <ul style={{
                    display: "flex", 
                    width:"100%", 
                    paddingLeft:"0px", 
                    backgroundColor:"#197770", 
                    color:"#FFF",
                    fontFamily: 'Heiti SC, sans-serif',
                    fontSize:"24px",
                    fontWeight:"400",
                    height:"41px",
                    marginTop: "20px",
                    marginLeft:"30px"}}>  
                <li style={{width:"100%", textAlign: "center", marginTop:"2px" }}>{title}</li>   
                <li style={{width:"15%", textAlign: "center", marginTop:"-15px" }}>
                  <Dropdown style={{position:"inherit"}}>
                      <Dropdown.Toggle as={CustomToggle} id="dropdown-toggle" />
                      <Dropdown.Menu>
                        {IsStorageEnvDev() && <Dropdown.Item onClick={(e)=> {
                          e.preventDefault();
                          handleTitle("Sessioner", "/")}} href="/">Sessioner</Dropdown.Item>}
                        {<Dropdown.Item onClick={(e)=> {
                          e.preventDefault();
                          handleTitle("Lektie", IsStorageEnvDev() ? "/memorization" : "/")}}  href={IsStorageEnvDev() ? "/memorization" : "/"}>Lektie</Dropdown.Item>}
                        {UserHasRoles(userContext,["Administrator"]) && <Dropdown.Item onClick={(e)=> {
                          e.preventDefault();
                          handleTitle("Elev fraværs rapport", "/studentsabsence")}}  href="/studentsabsence">Elev fraværs rapport</Dropdown.Item>}
                        {UserHasRoles(userContext,["Administrator"]) && <Dropdown.Item onClick={(e)=> {
                          e.preventDefault();
                          handleTitle("Register betaling", "/registerpayment")}} href="/registerpayment">Register betaling</Dropdown.Item>}
                        {UserHasRoles(userContext,["Administrator"]) && <Dropdown.Item onClick={(e)=> {
                          e.preventDefault();
                          handleTitle("Betalings rapport", "/reportpayment")}} href="/reportpayment">Betalings rapport</Dropdown.Item>}
                        {UserHasRoles(userContext,["Administrator"]) && <Dropdown.Item onClick={(e)=> {
                          e.preventDefault();
                          handleTitle("Opret profil", "/createprofile")}} href="/createprofile">Opret profil</Dropdown.Item>}
                        <Dropdown.Item onClick={(e)=> {
                          e.preventDefault();
                          handleTitle("Ret profil", "/editprofile")}} href="/editprofile">Ret profil</Dropdown.Item>
                        <Dropdown.Item onClick={(e)=> {
                          e.preventDefault();
                          handleTitle("Log ud", "/logout")}} href="/logout">Log ud</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
           </ul>
           </div>
  }
  

  return (
      <>
        {vectorLinks()}
        <br></br>
      </>
  );
}
