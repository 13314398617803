import React, { ChangeEvent, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { IMemorizationChapter } from '../../models/memorization-chapter';
import { Form } from 'react-bootstrap';
import { Chapters } from '../../consts/surah-const';
import { MemorizationChapterOptionStatus } from '../../enums/status-enums';
import { IMemorization } from '../../models/memorization';

interface FormValues {
  [key: string]: string;
}

export interface IMemorizationChapterModal {
  memorizationChapters: IMemorizationChapter[];
  onUpdateMemorizationChapters: (memorizationChapters: IMemorizationChapter[])=> void;
  memorizationChapterOptionStatus: MemorizationChapterOptionStatus;
  lastFinishedMemorization: IMemorization;
}

function MemorizationChapterOptionModal(props: IMemorizationChapterModal) {
 
   const {onUpdateMemorizationChapters, memorizationChapters, memorizationChapterOptionStatus, lastFinishedMemorization} = props;
   const [selectedValues, setSelectedValues] = useState<FormValues>({});
   const [memorizationChaptersState, setMemorizationChapters] = useState(memorizationChapters);
   const [show, setShow] = useState(false);
   const [searchChapterValueState, setSearchChapterValueState] = useState("");

   console.log(lastFinishedMemorization);

   useEffect(()=>{
    if(memorizationChapters)
    {
      setMemorizationChapters(memorizationChapters);
    }
   },[memorizationChapters])

    const onCloseModal = () => {
        setShow(false);
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>, chapterDisplayName: any, type: number) => {
      const { name, value } = event.target;
      let selectedValuesLocal = {...selectedValues};

      if (selectedValuesLocal.hasOwnProperty(name) && selectedValuesLocal[name] === value) {
        delete selectedValuesLocal[name];

        const chapterIdLocal = value.split('_').shift();
        setSelectedValues(selectedValuesLocal);
        setMemorizationChapters(memorizationChaptersState.filter(mc=> mc.chapterId.toLocaleLowerCase() !== chapterIdLocal?.toLocaleLowerCase() ))
        return;
      }
      
      setSelectedValues({
          ...selectedValuesLocal,
          [name]: value
      });
  
      setMemorizationChapterFromOption(value as string, chapterDisplayName, type);
    };

    const setMemorizationChapterFromOption = (chapterId: any, chapterDisplayName: any, type: number) =>
    {
  
      const chapterIdLocal = chapterId.split('_').shift();
  
      let memorizationChaptersLocal = [...memorizationChaptersState];
  
      const memoChapter = Chapters.filter(x=> x.chapterId.toLocaleLowerCase() === chapterIdLocal.toLocaleLowerCase())[0];
      if(type === 1 || type === 2){
        memorizationChaptersLocal = memorizationChaptersLocal.filter(mc => mc.chapterId.toLocaleLowerCase() !== chapterIdLocal.toLocaleLowerCase());
        memorizationChaptersLocal.push({
          chapterId: chapterIdLocal, 
          chapterDisplayName,
          name: memoChapter.name,
          nameArabic: memoChapter.nameArabic, 
          memorizationType : type, 
          fromAyah: 1, 
          toAyah: Number(memoChapter.numberOfVerses) 
        } as IMemorizationChapter);
      }
  
      if(type === 3){
          memorizationChaptersLocal = memorizationChaptersLocal.filter(mc => mc.chapterId.toLocaleLowerCase() !== chapterIdLocal.toLocaleLowerCase());
          memorizationChaptersLocal.push({
            chapterId: chapterIdLocal, 
            chapterDisplayName, 
            name: memoChapter.name,
            nameArabic: memoChapter.nameArabic, 
            memorizationType : 1, 
            fromAyah: 1, 
            toAyah: Number(memoChapter.numberOfVerses) 
          } as IMemorizationChapter);
          memorizationChaptersLocal.push({
            chapterId: chapterIdLocal, 
            chapterDisplayName, 
            name: memoChapter.name,
            nameArabic: memoChapter.nameArabic, 
            memorizationType : 2, 
            fromAyah: 1, 
            toAyah: Number(Chapters.filter(x=> x.chapterId.toLocaleLowerCase() === chapterIdLocal.toLocaleLowerCase())[0].numberOfVerses) 
          } as IMemorizationChapter);
      }
  
      setMemorizationChapters(memorizationChaptersLocal);
  
    }

    const handleShowModal = ()=> {
        setShow(true);
        if(memorizationChapterOptionStatus === MemorizationChapterOptionStatus.Edit){
          setSelectedCheckboxValues();
        }
    }

    const handleUpdateMemorizationChapters = ()=> {
      setShow(false);
      setSearchChapterValueState("");
      onUpdateMemorizationChapters(memorizationChaptersState);
    }

    const setSelectedCheckboxValues = () => {
        let selectedValuesLocal = {...selectedValues};
    
        memorizationChapters.forEach((mc)=>{
          const mcLength = memorizationChapters.filter(c=> c.chapterId === mc.chapterId).length;

          if(mc.memorizationType === 1  && mcLength === 1){
            selectedValuesLocal[`${mc.chapterId.toLocaleUpperCase()}_optionGroup`] = `${mc.chapterId.toLocaleUpperCase()}_value1`;
          }
          if(mc.memorizationType === 2 && mcLength === 1){
            selectedValuesLocal[`${mc.chapterId.toLocaleUpperCase()}_optionGroup`] = `${mc.chapterId.toLocaleUpperCase()}_value2`;
          }
          if(mcLength === 2){
            selectedValuesLocal[`${mc.chapterId.toLocaleUpperCase()}_optionGroup`] = `${mc.chapterId.toLocaleUpperCase()}_value3`;
          }
        });
        
        setSelectedValues(selectedValuesLocal);
    };

    const searchChapterValue = async (searchValue: any) => {
      const value = searchValue.target.value; 
      setSearchChapterValueState(value);
   };

    const handleFromAyahChange = (e: any, chapterId: string, type: number) => {
      const fromAyahLocal = e.target.value as number;
      var memorizationChapters = memorizationChaptersState.map((mc)=>{
        if(mc.chapterId.toLocaleLowerCase() === chapterId.toLocaleLowerCase() && mc.memorizationType === type){
          return {...mc,
                     fromAyah: fromAyahLocal, 
                     fromAyahError: !fromAyahLocal || Number(fromAyahLocal) === 0 ||
                      Number(fromAyahLocal) > Chapters.filter(ch=> ch.chapterId === chapterId)[0].numberOfVerses ? "Error" : ""
                }
        }
        return mc;
      });

      setMemorizationChapters(memorizationChapters);
    }

    const handleToAyahChange = (e: any, chapterId: string,  type: number) => {
      const toAyahLocal = e.target.value as number;

      var memorizationChapters = memorizationChaptersState.map((mc)=>{
        if(mc.chapterId.toLocaleLowerCase() === chapterId.toLocaleLowerCase() && mc.memorizationType === type){
          return {...mc, 
                     toAyah: toAyahLocal, 
                     toAyahError: !toAyahLocal || Number(toAyahLocal) === 0 ||
                      Number(toAyahLocal) > Chapters.filter(ch=> ch.chapterId === chapterId)[0].numberOfVerses ? "Error" : ""
                  }
        }
        return mc;
      });

      setMemorizationChapters(memorizationChapters);
    }

    const chapters = ()=>{

      const memorizationChapters = Chapters.map((ch)=>{

        var memoChapter = memorizationChaptersState?.filter(mc=>mc.chapterId.toLocaleLowerCase() === ch.chapterId.toLocaleLowerCase());

        return {
            chapterId: ch.chapterId,
            chapterNumber: ch.chapternumber,
            name: ch.name,
            nameArabic: ch.nameArabic,
            numberOfVerses: ch.numberOfVerses,
            fromAyah: memoChapter.length === 2 
                ? memorizationChaptersState?.filter(mc=>mc.chapterId.toLocaleLowerCase() === ch.chapterId.toLocaleLowerCase() && mc.memorizationType === 1)[0]?.fromAyah
                : memorizationChaptersState?.filter(mc=>mc.chapterId.toLocaleLowerCase() === ch.chapterId.toLocaleLowerCase())[0]?.fromAyah,
            toAyah: memoChapter.length === 2
                 ? memorizationChaptersState?.filter(mc=>mc.chapterId.toLocaleLowerCase() === ch.chapterId.toLocaleLowerCase() && mc.memorizationType === 1)[0]?.toAyah
                 : memorizationChaptersState?.filter(mc=>mc.chapterId.toLocaleLowerCase() === ch.chapterId.toLocaleLowerCase())[0]?.toAyah,
            fromAyahError: memorizationChaptersState?.filter(mc=>mc.chapterId.toLocaleLowerCase() === ch.chapterId.toLocaleLowerCase())[0]?.fromAyahError,
            toAyahError: memorizationChaptersState?.filter(mc=>mc.chapterId.toLocaleLowerCase() === ch.chapterId.toLocaleLowerCase())[0]?.toAyahError,
            bothFromAyah:  memoChapter.length === 2 
                ? memorizationChaptersState?.filter(mc=>mc.chapterId.toLocaleLowerCase() === ch.chapterId.toLocaleLowerCase() && mc.memorizationType === 2)[0]?.fromAyah
                :  memorizationChaptersState?.filter(mc=>mc.chapterId.toLocaleLowerCase() === ch.chapterId.toLocaleLowerCase())[0]?.fromAyah,
            bothToAyah:  memoChapter.length === 2 
                ? memorizationChaptersState?.filter(mc=>mc.chapterId.toLocaleLowerCase() === ch.chapterId.toLocaleLowerCase() && mc.memorizationType === 2)[0]?.toAyah
                :  memorizationChaptersState?.filter(mc=>mc.chapterId.toLocaleLowerCase() === ch.chapterId.toLocaleLowerCase())[0]?.toAyah,
            
        } as IMemorizationChapter
      });

      return searchChapterValueState 
        ? [...memorizationChapters.filter(ch=>ch.name.toLocaleLowerCase().includes(searchChapterValueState.toLocaleLowerCase()) 
                                      || ch.chapterNumber.toString().toLocaleLowerCase() === searchChapterValueState.toLocaleLowerCase())].reverse()
        : [...memorizationChapters].reverse();
    }

    const hasSelectedHifdOrMurajaValue = (hasHifdValue: boolean, hasMurajaValue: boolean, hasBoth:boolean)=>{
      return hasHifdValue || hasMurajaValue || hasBoth;
    }

    const hasSelectedHifdAndMurajaValue = ( hasBoth:boolean)=>{
      return hasBoth;
    }

    const chaptersLocal = chapters()

    return (
      <>
        <div style={{position: "relative", display:"inline-block", width:"95%", marginBottom:"20px"}}>
          <input placeholder={memorizationChaptersState.length === 0 
                ? '0 er valgt' 
                : `${memorizationChaptersState.length} er valgt`} 
            id="memorizationChapterInput"
            readOnly
            autoFocus
            onClick={handleShowModal}
            style={{
              marginLeft:"1px", 
              marginRight:"5px", 
              width:"100%", 
              height:"40px", 
              borderRadius:"6px"}} 
            type="text">
          </input>
        </div>
       
        <Modal style={{borderRadius:"15px"}} show={show} onHide={onCloseModal} shouldUpdate={false}>
          <Modal.Body>
              <div style={{marginBottom: "20px"}}>
              <div style={{marginLeft:"4px", marginBottom:"10px", position: "relative", display:"inline-block", width:"95%"}}>
                  <input placeholder='Søg på surah navn eller nummer' value={searchChapterValueState} 
                  onChange={(value:any) => searchChapterValue(value)} 
                  style={{marginLeft:"5px", marginRight:"5px", width:"100%", height:"40px", borderRadius:"6px", borderColor: "#1D5F35"}} 
                  type="text"></input>
              </div>
                {
                   <Form>
                    <div >
                      <div style={{ height:"100%"}}>
                      {chaptersLocal.map( (ja) => (
                        <Form.Group key={ja.chapterId}>
                              <div
                                 style={{borderTop:"solid 1px", padding:"10px", borderColor:"lightgray"}} 
                                 key={`${ja.chapterId}_checkboxDiv`}>
                                  <label style={{marginBottom:"10px"}}>{`${ja.name} - ( ${ja.nameArabic}) `}</label>
                                  <br></br>
                                  <Form.Check 
                                        key={`${ja.chapterId}_checkboxHifz`}
                                        name={`${ja.chapterId}_optionGroup`}
                                        type="checkbox"
                                        id={`${ja.chapterId}_checkboxHifz`}
                                        label="Hifz"
                                        value={`${ja.chapterId}_value1`}
                                        checked={selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value1`}
                                        inline
                                        onChange={(e:any) => handleInputChange(e, `${ja.name} - ( ${ja.nameArabic}) `, 1)}
                                  />
                                  <Form.Check 
                                        key={`${ja.chapterId}_checkboxMurajara`}
                                        name={`${ja.chapterId}_optionGroup`}
                                        type="checkbox"
                                        id={`${ja.chapterId}_checkboxMurajara`}
                                        label="Murajara"
                                        value={`${ja.chapterId}_value2`}
                                        checked={selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value2`}
                                        inline
                                        onChange={(e:any) => handleInputChange(e, `${ja.name} - ( ${ja.nameArabic}) `, 2)}
                                  />
                                  <Form.Check 
                                        key={`${ja.chapterId}_checkboxBoth`}
                                        name={`${ja.chapterId}_optionGroup`}
                                        type="checkbox"
                                        id={`${ja.chapterId}_checkboxBoth`}
                                        label="Both"
                                        value={`${ja.chapterId}_value3`}
                                        checked={selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value3`}
                                        inline
                                        onChange={(e:any) => handleInputChange(e, `${ja.name} - ( ${ja.nameArabic}) `, 3)}
                                  />
                                  <br></br>
                                {selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value3` && 
                                    <label><b>Hifd</b></label>
                                }
                                 {hasSelectedHifdOrMurajaValue(selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value1`,
                                  selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value2`,
                                  selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value3`
                                 ) && <div style={{display:"flex", marginBottom: "10px", marginTop:"8px"}}>
                                    <label style={{marginBottom: "15px"}}>Fra ayah: </label>
                                    <input value={ja.fromAyah} 
                                        onChange={(event:any)=> 
                                          handleFromAyahChange(event, ja.chapterId, 
                                            selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value1` ||
                                            selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value3` ? 1 : 2)}
                                          type="number" min="1" max={ja.numberOfVerses.toString()}  
                                          className="form-control"
                                        style={{marginLeft:"20px", width:"20%", height:"30px", borderRadius:"6px", borderColor: "#0d6efd"}}>
                                    </input>
                                 </div>}
                                {hasSelectedHifdOrMurajaValue(selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value1`,
                                  selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value2`,
                                  selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value3`
                                 ) && <div style={{display:"flex"}}>
                                    <label style={{marginBottom: "10px"}}>Til ayah: </label>
                                    <input value={ja.toAyah} onChange={(event:any)=>
                                            handleToAyahChange(event, ja.chapterId, 
                                              selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value1` ||
                                              selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value3` ? 1 : 2)} 
                                            type="number" min="1" max={ja.numberOfVerses.toString()}   
                                            className="form-control"
                                        style={{marginLeft:"26px", width:"20%", height:"30px", borderRadius:"6px", borderColor: "#0d6efd"}}>
                                    </input>
                                </div>
                                }
                                {hasSelectedHifdAndMurajaValue(selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value3`) && 
                                    <label><b>Murajara</b></label>
                                }
                                 {hasSelectedHifdAndMurajaValue(selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value3`) && 
                                 <div style={{display:"flex", marginBottom: "10px", marginTop:"8px"}}>
                                    <label style={{marginBottom: "15px"}}>Fra ayah: </label>
                                    <input value={ja.bothFromAyah}  onChange={(event:any)=> handleFromAyahChange(event, ja.chapterId, 2)} type="number" min="1" max={ja.numberOfVerses.toString()}  className="form-control"
                                        style={{marginLeft:"20px", width:"20%", height:"30px", borderRadius:"6px", borderColor: "#0d6efd"}}>
                                    </input>
                                 </div>}
                                {hasSelectedHifdAndMurajaValue(selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value3`) && 
                                  <div style={{display:"flex"}}>
                                    <label style={{marginBottom: "10px"}}>Til ayah: </label>
                                    <input value={ja.bothToAyah} onChange={(event:any)=>handleToAyahChange(event, ja.chapterId,2)} type="number" min="1" max={ja.numberOfVerses.toString()}   className="form-control"
                                        style={{marginLeft:"26px", width:"20%", height:"30px", borderRadius:"6px", borderColor: "#0d6efd"}}>
                                    </input>
                                </div>
                                }
                                {(ja.fromAyahError || ja.toAyahError) && <label style={{color:"red"}}>Vers nummer skal være mellem 1 - {ja.numberOfVerses}</label>}
                              </div>
                        </Form.Group>
                        ))} 
                      </div>
                    </div>
                 </Form>
                }

              </div>
          </Modal.Body>
  
          <Modal.Footer style={{
            position:"sticky",
            bottom:"0",
            backgroundColor:"#fff",
            padding:"20px",
            
            }}>
            <Button style={{backgroundColor:"#1D5F35"}} variant="primary" 
                  onClick={handleUpdateMemorizationChapters}
                  disabled={memorizationChaptersState?.some((mc=> mc.fromAyahError || mc.toAyahError))}>
              Gem
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default MemorizationChapterOptionModal;