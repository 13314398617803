import { EnvironmentStatus } from "../enums/status-enums"
import { IFeatureToggle } from "./feature-toggle";
import { IFeatureToggleContext, useFeatureToggleContext } from "./feature-toggle-context";


export const IsDevEnvironment = () => {

   const {featureKeyValuePairsContext} = useFeatureToggleContext();
 
    if(!featureKeyValuePairsContext) return false;
   
    const environment = featureKeyValuePairsContext.filter(f=>f.key.toLowerCase() === "environment")[0];

    if(!environment) return false;

    return environment.value.toLowerCase() === EnvironmentStatus.Development || environment.value.toLowerCase() === EnvironmentStatus.AzureDev; 
}

export const IsDevelopmentEnvironment = (environment: string) => {
  if(!environment) return false;

  return environment.toLowerCase() === EnvironmentStatus.Development || environment.toLocaleLowerCase() === EnvironmentStatus.AzureDev; 
}

export const IsStorageEnvDev = () => {
  return GetEnvironmentSessionStorage()?.toLowerCase() === EnvironmentStatus.Development || GetEnvironmentSessionStorage()?.toLocaleLowerCase() === EnvironmentStatus.AzureDev; 
}

export const SetEnvironmentSessionStorage = (environment:string)=>{
  sessionStorage.setItem("environment", environment);
}

export const GetEnvironmentSessionStorage = ()=>{
  return sessionStorage.getItem("environment");
}

export const SetFeatureSessionStorage = (features:IFeatureToggle[])=>{
  sessionStorage.setItem("features", JSON.stringify(features));
}

export const GetFeatureSessionStorage = ()=>{
  return sessionStorage.getItem("features");
}