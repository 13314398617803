import React, { createContext, useContext, useEffect, useState } from "react";
import { IFeatureToggle } from "./feature-toggle";
import { FeatureService, IFeatureService } from "../services/feature-service";
import { GetFeatureSessionStorage, SetFeatureSessionStorage } from "./feature-toggle-helper";
import { getAuthDataFromLocalStorage } from "../components/auth/model/auth-utils";

export interface IFeatureToggleContext {
  featureKeyValuePairsContext: IFeatureToggle[];
}

const FeatureToggleContext = createContext({} as IFeatureToggleContext);

export const useFeatureToggleContext = () => {
  const context = useContext(FeatureToggleContext);
  if (!context) {
    throw new Error(`useFeatureToggleContext must be used within a FeatureToggleProvider`);
  }
  return context;
};

export const FeatureToggleProvider = (props: React.PropsWithChildren) => {

  const featureService: IFeatureService = new FeatureService();

  const { children} = props;

  const [featureToggleState, setFeatureToggleState] = useState<IFeatureToggleContext>( { featureKeyValuePairsContext: []});

  useEffect(() => {
    getFeatureToggles();
  }, []);

  const getFeatureToggles = async () => {

    const authData = getAuthDataFromLocalStorage();

    if(!authData.userId) return;

    const features = GetFeatureSessionStorage();

    if(features){
      setFeatureToggleState({featureKeyValuePairsContext: JSON.parse(features) as IFeatureToggle[]});
      return;
    }
    else{
      await featureService
        .getFeatureToggles()
        .then((data) => {
          if (data) {
            setFeatureToggleState({featureKeyValuePairsContext: data.featureKeyValuePairs});
            SetFeatureSessionStorage(data.featureKeyValuePairs);
          }
      });
    }
  };

  return (
    <FeatureToggleContext.Provider value={featureToggleState}>{children}</FeatureToggleContext.Provider>
  );
};
