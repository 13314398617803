import { BaseApiService, IBaseApiService } from "../../services/base-service";
import { IUser } from "../../user/user";
import { IAuthData, ILoginRequest, INewPasswordRequest, IResetPasswordRequest } from "./model/dto";

const url = `api/Account/`;
const loginAction = `Login`;
const registerAction = `Register`;
const resetPasswordAction = `ResetPassword`;
const newPasswordAction = `NewPassword`;

export interface IAuthService {
  login(loginRequest: ILoginRequest): Promise<IAuthData | any>;
  register(user: IUser): Promise<any>;
  resetPassword(resetPasswordRequest: IResetPasswordRequest): Promise<any | boolean>;
  newPassword(newPasswordRequest: INewPasswordRequest): Promise<any | boolean>;
}

export class AuthService implements IAuthService {
  private readonly baseService: IBaseApiService;
  constructor() {
    this.baseService = new BaseApiService();
  }

  async login(loginRequest: ILoginRequest): Promise<IAuthData | any> {
    try {
      const response = await this.baseService.post<ILoginRequest, any>(
        `${url}${loginAction}`,
        loginRequest
      );

      const data = await response;
      if (data.userId) {
        return {authData: data}
      }
      else{
        return {error: "Email or password is not correct"}
      }

    } catch (error: any) {
        return {error: "Something went wrong"}
    }
  }

  async resetPassword(resetPasswordRequest: IResetPasswordRequest): Promise<any | boolean> {
    try {
      return await this.baseService.post<any, boolean>(
        `${url}${resetPasswordAction}`,
        resetPasswordRequest
      );

    } catch (error: any) {
        return {error: "Something went wrong"}
    }
  }

  async newPassword(newPasswordRequest: INewPasswordRequest): Promise<any | boolean> {
    try {
      return await this.baseService.authPost<any, boolean>(
        `${url}${newPasswordAction}`,
        newPasswordRequest
      );

    } catch (error: any) {
        return {error: "Something went wrong"}
    }
  }

  async register(user: IUser): Promise<any> {
    try {
      return await this.baseService.post<IUser, any>(
        `${url}${registerAction}`,
        user
      );
    } catch (error) {
      return error;
    }
  }
}
