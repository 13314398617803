import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { IMemorizationSession } from '../../models/memorization-session';
import { MemorizationChapterOptionStatus, MemorizationType, SessionStatus } from '../../enums/status-enums';
import { Table } from 'react-bootstrap';
import './memorization-session-teacher-modal.css';
import { ICompleteMemorizationSessionRequest } from '../../models/complete-memorization-session-request';
import { useUserContext } from '../../user/user-context';
import { IMemorizationService, MemorizationService } from '../../services/memorization-service';
import { IMemorization } from '../../models/memorization';
import { IStudent } from '../../models/student';
import MemorizationHomeWorkModal from '../memorization/memorization-homework-modal';
import { ITeacher } from '../../models/teacher';
import { IMemorizationChapter } from '../../models/memorization-chapter';
import MemorizationChapterModal from '../memorization/memorization-chapter-modal';
import MemorizationChapterOptionModal from '../memorization/memorization-chapter-option-modal';
import AddCommentMemorizationSessionModal from './add-comment-memorization-session-modal';
import { AgoraContainer } from '../agora/agora-container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinimize } from '@fortawesome/free-solid-svg-icons';

interface IMemorizationSessionErrors{
  memorizationGradeError: string;
  repititionGradeError: string;
  newMemorizationMurajaraError: string;
}

export interface IMemorizationSessionModal {
    showModal: boolean;
    onHideModal: () => void;
    memorizationSession: IMemorizationSession;
    lastFinishedMemorization: IMemorization;
    onStartSession: (memorizationSessionId: string) => void;
    onCompleteSession: (completeMemorizationSessionRequest: ICompleteMemorizationSessionRequest, newMemorization: IMemorization) => void;
    onAddBackToQueue: (studentId: string, comment: string)=> void;
    onCreateMemorizationHomeWork: (studentId: string) => void;
    onUpdateMemorizationHomeWork: (memorization: IMemorization)=> void;
    onSetTab: (tabNumber: string) => void;
}

function MemorizationSessionTeacherModal(props: IMemorizationSessionModal) {

   const {showModal, memorizationSession, lastFinishedMemorization, onHideModal, onStartSession, onAddBackToQueue, 
    onCompleteSession, onCreateMemorizationHomeWork, onUpdateMemorizationHomeWork, onSetTab} = props;

   const [selectedMemorizationGrade, setMemorizationGrade] = useState("");
   const [selectedRepetitionGrade, setRepetitionGrade] = useState("");
   const [showAgoraModal, setshowAgoraModal] = useState(false);
   const [showAddCommentModal, setShowAddCommentModal] = useState(false);
   const {userContext} = useUserContext();
   const [studentState, setStudentState] = useState({
    student: {} as IStudent,
  });
  const [closeModal, setCloseModal] = useState(false);
  const [commentState, setComment] = useState<string | null>(null);
  const [completeSessionState, setCompleteSession] = useState(false);

  const [isModalMinimized, setIsModalMinimized] = useState(false);

  const [memorizationChapterRow, setMemorizationChapterRow] = useState({
    memorizationChapter: {} as IMemorizationChapter
  });

  const [newMemorizationChapterRow, setNewMemorizationChapterRow] = useState({
    memorizationChapter: {} as IMemorizationChapter
  });

  const [memorizationChaptersState, setMemorizationChapters] = useState(memorizationSession?.memorization?.memorizationChapters 
    ? memorizationSession?.memorization?.memorizationChapters : [] as IMemorizationChapter[] );

  const [newMemorizationChaptersState, setNewMemorizationChapters] = useState([] as IMemorizationChapter[] );

  const [openChapterOptionModal, setOpenChapterOptionModal] = useState(false);

  const [newOpenChapterOptionModal, setNewOpenChapterOptionModal] = useState(false);

   const [errorState, setErrorState] = useState({
    error: { } as IMemorizationSessionErrors
   });

   const [leaveChannelState, setLeaveChannel] = useState(false);

   const memorizationService: IMemorizationService = new MemorizationService();

   useEffect(()=>{
    setStudentState({student: memorizationSession?.student});
   },[memorizationSession?.student]);

   useEffect(()=>{
    if(memorizationSession?.memorization?.memorizationChapters){
      setMemorizationChapters(memorizationSession.memorization.memorizationChapters);
    }
  },[memorizationSession?.memorization?.memorizationChapters]);

  useEffect(()=>{
    if(memorizationSession?.memorization?.memorizationGrade){
      setMemorizationGrade(memorizationSession?.memorization?.memorizationGrade.toString())
    }
  },[memorizationSession?.memorization?.memorizationGrade]);

  useEffect(()=>{
    if(memorizationSession?.memorization?.repetitionGrade){
      setMemorizationGrade(memorizationSession?.memorization?.repetitionGrade.toString())
    }
},[memorizationSession?.memorization?.repetitionGrade]);

  const rowClick = (memorizationChapter: IMemorizationChapter) => {
    
    setOpenChapterOptionModal(true);
    setMemorizationChapterRow({
     memorizationChapter: memorizationChaptersState.
     filter(mc=> mc.chapterId === memorizationChapter.chapterId && mc.memorizationType === memorizationChapter.memorizationType)[0]});
  }

  const newRowClick = (memorizationChapter: IMemorizationChapter) => {
    
    setNewOpenChapterOptionModal(true);
    setNewMemorizationChapterRow({
     memorizationChapter: newMemorizationChaptersState.
     filter(mc=> mc.chapterId === memorizationChapter.chapterId && mc.memorizationType === memorizationChapter.memorizationType)[0]});
  }

    const onCloseModal = () => {
        if(showAgoraModal){
          setLeaveChannel(true);
          setCloseModal(true);
        }
        else{
          closeTeacherModal();
        }
    };

    const closeTeacherModal = ()=>{
        onHideModal();
        setMemorizationGrade("");
        setRepetitionGrade("");
        setMemorizationChapters([]);
        setNewMemorizationChapters([]);
        setMemorizationChapterRow({memorizationChapter: {} as IMemorizationChapter});
        setNewMemorizationChapterRow({memorizationChapter: {} as IMemorizationChapter});
        setErrorState({error: {} as IMemorizationSessionErrors});
    }

    const onClickStartSession = ()=> {
        onStartSession(memorizationSession.memorizationSessionId);
    }

    const onClickCompleteSession = ()=> {
        if(checkForErrors()) return;

        if(showAgoraModal){
          setLeaveChannel(true);
          setCompleteSession(true);
        }
        else{
          completeSession();
        }
     }

     const completeSession = ()=>{
        const newMemorization = {
          memorizationChapters: newMemorizationChaptersState,
          student: memorizationSession.memorization.student,
          teacher: memorizationSession.memorization.teacher
        } as IMemorization;

        const completeMemoSession = {
          memorizationSessionId: memorizationSession.memorizationSessionId,
          memorizationId: memorizationSession.memorization.memorizationId,
          teacherId: userContext.teacherId,
          memorizationGrade: Number(selectedMemorizationGrade),
          repetitionGrade: Number(selectedRepetitionGrade)
        } as ICompleteMemorizationSessionRequest

        onCompleteSession(completeMemoSession, newMemorization);
        
        onHideModal();
     }
    
    const onClickAddBackToQueue = ()=>{
      setShowAddCommentModal(true);
    }

    const handleSelectMemorizationGrade=(e:any)=>{
      setMemorizationGrade(e);

      const memorizationLocal = {
        memorizationId: memorizationSession.memorization.memorizationId,
        memorizationChapters: memorizationChaptersState,
        student: memorizationSession.memorization.student,
        teacher: memorizationSession.memorization.teacher,
        memorizationGrade: e,
        repetitionGrade: selectedRepetitionGrade === "" ? null : selectedRepetitionGrade
      } as IMemorization;

      onUpdateMemorizationHomeWork(memorizationLocal);
    }
  
    const handleSelectRepitionGrade=(e:any)=>{
      setRepetitionGrade(e);

      const memorizationLocal = {
        memorizationId: memorizationSession.memorization.memorizationId,
        memorizationChapters: memorizationChaptersState,
        student: memorizationSession.memorization.student,
        teacher: memorizationSession.memorization.teacher,
        memorizationGrade: selectedMemorizationGrade === "" ? null : selectedMemorizationGrade,
        repetitionGrade: e
      } as IMemorization;

      onUpdateMemorizationHomeWork(memorizationLocal);
    }

    const handleCreateMemorizationHomeWork = async (memorization: IMemorization) => {
      await memorizationService
        .createMemorizationHomework(memorization)
        .then((data) => {
          if (data) {
            onCreateMemorizationHomeWork(studentState.student.studentId);
          }
        });
    };
  

    const getGradeName = (selectedElement: string)=> {
      switch(selectedElement){
        case "1":
          return "Perfekt";
        case "2":
          return "Meget godt";
        case "3":
          return "Godt";
        case "4":
          return "Har ikke memporiseret";
      }
    }

    const checkForErrors = ()=> {
  
      let errors = {} as IMemorizationSessionErrors;
      if(!selectedMemorizationGrade && memorizationChaptersState.find(mc=>mc.memorizationType === 1)){
         errors.memorizationGradeError = "Hifd karakter værdi skal være valgt" 
      }
      if(!selectedRepetitionGrade && memorizationChaptersState.find(mc=>mc.memorizationType === 2)){
        errors.repititionGradeError = "Murajara karakter værdi skal være valgt" 
      }
      if(!newMemorizationChaptersState || newMemorizationChaptersState.length === 0){
        errors.newMemorizationMurajaraError = "Ny hifd/murajara skal tilføjes"
      }

      setErrorState({
        error: errors
      });
  
      if(errors.memorizationGradeError || errors.repititionGradeError || errors.newMemorizationMurajaraError){  
        return true;
      }

      return false;
    }

    const startVideoCall = ()=> {
      setshowAgoraModal(true);
    }

    const handleOnHideModal = () => {
      setOpenChapterOptionModal(false);
    }

    const handleOnNewHideModal = () => {
      setNewOpenChapterOptionModal(false);
    }

    const handleLeaveChannel = () => {
      setLeaveChannel(false);
      setshowAgoraModal(false);

      if(closeModal){
        closeTeacherModal()
        return;
      }

      if(commentState || commentState == ""){
        addComment(commentState);
        return;
      }

      if(completeSessionState){
        completeSession();
        return;
      }
  }

    const handleChangeMemorizationChapter = (memorizationChapter: IMemorizationChapter) => {

      const memoChapters = memorizationChaptersState.map(mc => 
        mc.chapterId === memorizationChapter.chapterId && mc.memorizationType === memorizationChapter.memorizationType
        ? memorizationChapter 
        : mc);

      setMemorizationChapters(memoChapters)

      const memorizationLocal = {
        memorizationId: memorizationSession.memorization.memorizationId,
        memorizationChapters: memoChapters,
        student: memorizationSession.memorization.student,
        teacher: memorizationSession.memorization.teacher,
        memorizationGrade: selectedMemorizationGrade ? selectedMemorizationGrade : null,
        repetitionGrade: selectedRepetitionGrade ? selectedRepetitionGrade : null
      } as IMemorization;

      onUpdateMemorizationHomeWork(memorizationLocal);

    }

    const handleChangeNewMemorizationChapter = (memorizationChapter: IMemorizationChapter) => {

      const memoChapters = newMemorizationChaptersState.map(mc => 
        mc.chapterId === memorizationChapter.chapterId && mc.memorizationType === memorizationChapter.memorizationType
        ? memorizationChapter 
        : mc);

      setNewMemorizationChapters(memoChapters)
    }

    const handleUpdateMemorizationChapters = (memorizationChapters: IMemorizationChapter[]) => {
      const memoChapters = memorizationChapters.map(mc=> {
        return mc.memorizationId ? mc : {...mc, MemorizationChapterId: memorizationSession.memorization.memorizationId}; 
      });  
      setMemorizationChapters(memoChapters);

      const memorizationLocal = {
        memorizationId: memorizationSession.memorization.memorizationId,
        memorizationChapters: memoChapters,
        student: memorizationSession.memorization.student,
        teacher: memorizationSession.memorization.teacher
      } as IMemorization;

      onUpdateMemorizationHomeWork(memorizationLocal);
    }

    const handleNewMemorizationChapters = (memorizationChapters: IMemorizationChapter[]) => {
      const memoChapters = memorizationChapters.map(mc=> {
        return mc.memorizationId ? mc : {...mc, MemorizationChapterId: memorizationSession.memorization.memorizationId}; 
      });  
      setNewMemorizationChapters(memoChapters);
    }

    const handleCloseAddCommentModal = () => {
      if(showAgoraModal){
        setLeaveChannel(true);
        setComment("");
      }
      else{
        addComment("");
      }
    }

    const handleAddCommentModal = (comment: string) => {
      if(showAgoraModal){
        setLeaveChannel(true);
        setComment(comment);
      }
      else{
        addComment(comment);
      }  
    }

   const addComment = (comment: string)=>{
      setShowAddCommentModal(false);
      onAddBackToQueue(memorizationSession.memorizationSessionId, comment);
      setComment(null);
      onHideModal();
   }

   const handleMinimize = () => setIsModalMinimized(true);

   const handleRestore = () => {
      setIsModalMinimized(false);
      onSetTab("2");
   };

    return (
      <>         
         {memorizationChapterRow.memorizationChapter && memorizationChapterRow.memorizationChapter.chapterId &&
            <MemorizationChapterModal showModal={openChapterOptionModal} 
              memorizationChapter={memorizationChapterRow.memorizationChapter}
              onHideModal={handleOnHideModal}
              onChangeMemorizationChapter ={handleChangeMemorizationChapter}/>
         }
        {newMemorizationChapterRow.memorizationChapter && newMemorizationChapterRow.memorizationChapter.chapterId &&
            <MemorizationChapterModal showModal={newOpenChapterOptionModal} 
              memorizationChapter={newMemorizationChapterRow.memorizationChapter}
              onHideModal={handleOnNewHideModal}
              onChangeMemorizationChapter ={handleChangeNewMemorizationChapter}/>
         }
        <AddCommentMemorizationSessionModal 
           openDialog = {showAddCommentModal}
           closeDialog={handleCloseAddCommentModal}
           onAddComment={handleAddCommentModal}/>
        {!isModalMinimized && <Modal show={showModal} onHide={onCloseModal}  backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Hifd session</Modal.Title>
            <div style={{ display: "flex", marginLeft:"45%", alignItems: "end" }}>
                <button
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={handleMinimize}
                >
                  <FontAwesomeIcon icon={faMinimize} />
                </button>
            </div>
          </Modal.Header>
          <Modal.Body>
           {showAgoraModal &&
          <AgoraContainer 
              channelName={memorizationSession?.student?.number.toString()}
              leaveChannel={leaveChannelState}
              onLeaveChannel={handleLeaveChannel}
              />
          }
          <div>
          <label style={{marginBottom: "10px"}}>Navn: {memorizationSession?.student?.fullName}</label>
          <br></br>
          <label style={{marginBottom: "10px"}}>Telefon: {memorizationSession?.student?.phone}</label>
          <br></br>
          <label style={{marginBottom: "10px"}}>Elev nummer: {memorizationSession?.student?.number}</label>
          <br></br>
          {<div style={{display: "flex" , marginBottom: "10px"}}><label>Online: </label>
            {memorizationSession.status === SessionStatus.Inprog  ? <div style={{marginLeft:"5px", marginTop:"-5px"}}>
            <Button disabled={!memorizationSession.online || (!memorizationSession.online && memorizationChaptersState.length === 0)} 
                    style={{backgroundColor:"#1D5F35", 
                    opacity: !memorizationSession.online || (!memorizationSession.online && memorizationChaptersState.length === 0) ? 0.6 : 1}} 
                    onClick={()=>{startVideoCall()}}>
              Start video kald
            </Button>
            </div> :<Form>
              <Form.Check 
                type="checkbox"
                id="custom-checkbox"
                disabled={true}
                style={{marginLeft: "5px"}}
                defaultChecked={memorizationSession.online}
              />
            </Form>}
            </div>}
        <div style={{marginTop:"30px", marginBottom:"20px", marginLeft: "-10px"}}>
              {memorizationSession.status === SessionStatus.Inprog && studentState.student && studentState.student.studentId && <MemorizationHomeWorkModal 
                  student={studentState.student} 
                  teacher={{teacherId: userContext.teacherId, fullName: userContext.fullName } as ITeacher}
                  onCreateMemorizationHomeWork = {handleCreateMemorizationHomeWork}
                  hasUncompletedMemorizationHomeWork = {memorizationChaptersState.length > 0}
                  lastFinishedMemorization ={lastFinishedMemorization}/>}
        </div>
        {memorizationChaptersState?.length > 0 && memorizationSession.status === SessionStatus.Inprog && <MemorizationChapterOptionModal 
                      memorizationChapters={memorizationChaptersState}
                      onUpdateMemorizationChapters={handleUpdateMemorizationChapters} 
                      memorizationChapterOptionStatus= {MemorizationChapterOptionStatus.Edit} 
                      lastFinishedMemorization={lastFinishedMemorization}/>
                      }
          <br></br>
          {memorizationChaptersState.length > 0 &&  <Table striped bordered hover>
            <thead>
              <tr>
                <th>Surah</th>
                <th>Fra</th>
                <th>Til</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
            {memorizationChaptersState.map((memorizationChapter, index) => (
                      <tr onClick={()=> rowClick(memorizationChapter)} key={index}>
                          <td>
                            {`${memorizationChapter.name} (${memorizationChapter.nameArabic})`}
                          </td>
                          <td>
                            {memorizationChapter.fromAyah}
                          </td>
                          <td>
                            {memorizationChapter.toAyah}
                          </td>
                          <td>
                            {memorizationChapter.memorizationType === MemorizationType.Hifz ? "Hifz" : "Murajara"}
                          </td>
                      </tr>
                    ))}
            </tbody>
          </Table>
         } 
          {memorizationChaptersState &&
           memorizationSession.status === SessionStatus.Inprog  && <div style={{display: "flex" ,marginBottom: "10px"}}>
            <label style={{marginBottom: "10px" }}>Hifd karakter:
            </label>
            { <DropdownButton 
              className="custom-dropdown" 
              onSelect={handleSelectMemorizationGrade} 
              style={{marginLeft: "45px", marginTop: "-5px"}} 
              key="dropdownkey" 
              id="dropdown-basic-button" title={!selectedMemorizationGrade ? "Hifd karakter" : getGradeName(selectedMemorizationGrade)}
              disabled={memorizationChaptersState.length ===0}>
              <Dropdown.Item eventKey="1">Perfekt</Dropdown.Item>
              <Dropdown.Item eventKey="2">Meget godt</Dropdown.Item>
              <Dropdown.Item eventKey="3">Godt</Dropdown.Item>
              <Dropdown.Item eventKey="4">Har ikke memoriseret</Dropdown.Item>
            </DropdownButton>
            }
          </div>}
          {errorState.error.memorizationGradeError &&  <label style={{marginBottom: "10px", color:"red"}}>{errorState.error.memorizationGradeError}</label>}
          {memorizationChaptersState &&
           memorizationSession.status === SessionStatus.Inprog  && <div style={{display: "flex" ,marginBottom: "10px"}}>
            <label style={{marginBottom: "10px" }}>Murajara karakter:
            </label>
            { <DropdownButton 
              className="custom-dropdown" 
              onSelect={handleSelectRepitionGrade} 
              style={{marginLeft: "10px", marginTop: "-5px"}} 
              key="dropdownkey" 
              id="dropdown-basic-button" title={!selectedRepetitionGrade ? "Murajara karakter" : getGradeName(selectedRepetitionGrade)}
              disabled={memorizationChaptersState.length ===0}>
              <Dropdown.Item eventKey="1">Perfekt</Dropdown.Item>
              <Dropdown.Item eventKey="2">Meget godt</Dropdown.Item>
              <Dropdown.Item eventKey="3">Godt</Dropdown.Item>
              <Dropdown.Item eventKey="4">Har ikke memoriseret</Dropdown.Item>
            </DropdownButton>
            }
          </div>}
          {errorState.error.repititionGradeError &&  <label style={{marginBottom: "10px", color:"red"}}>{errorState.error.repititionGradeError}</label>}
          <br></br>
          {memorizationSession.status === SessionStatus.Inprog && 
          memorizationChaptersState && memorizationChaptersState.length === 0 && 
          <label style={{color:"red", marginLeft:"5px"}}>Ingen memorisering er tilgængelig. Opret hjemmearbejde før du fortsætter med denne session</label>}
          
          {memorizationSession.status === SessionStatus.Inprog && <label style={{marginBottom: "10px"}}><b>Ny hifd/murajara:</b></label>}

          {memorizationChaptersState?.length > 0 && memorizationSession.status === SessionStatus.Inprog && <MemorizationChapterOptionModal 
                      memorizationChapters={newMemorizationChaptersState}
                      onUpdateMemorizationChapters={handleNewMemorizationChapters} 
                      memorizationChapterOptionStatus= {MemorizationChapterOptionStatus.Edit}
                      lastFinishedMemorization={lastFinishedMemorization} />}
          <br></br>
          {memorizationSession.status === SessionStatus.Inprog && newMemorizationChaptersState.length > 0 &&  <Table striped bordered hover>
            <thead>
              <tr>
                <th>Surah</th>
                <th>Fra</th>
                <th>Til</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
            {newMemorizationChaptersState.map((memorizationChapter, index) => (
                      <tr onClick={()=> newRowClick(memorizationChapter)} key={index}>
                          <td>
                            {`${memorizationChapter.name} (${memorizationChapter.nameArabic})`}
                          </td>
                          <td>
                            {memorizationChapter.fromAyah}
                          </td>
                          <td>
                            {memorizationChapter.toAyah}
                          </td>
                          <td>
                            {memorizationChapter.memorizationType === MemorizationType.Hifz ? "Hifz" : "Murajara"}
                          </td>
                      </tr>
                    ))}
            </tbody>
          </Table>
         } 
           {errorState.error.newMemorizationMurajaraError &&  <label style={{marginBottom: "10px", color:"red"}}>{errorState.error.newMemorizationMurajaraError}</label>}
          </div>
          </Modal.Body>
           <Modal.Footer>
           {memorizationSession.status === SessionStatus.Inprog && <Button style={{backgroundColor:"#1D5F35"}} variant="primary"
            onClick={()=>onClickAddBackToQueue()}>
              Læg tilbage i kø
            </Button>}
            {memorizationSession.status === SessionStatus.Waiting && 
            <Button
              style={{backgroundColor:"#1D5F35"}} variant="primary" onClick={onClickStartSession}>
              Start Session
            </Button> 
            }
            {memorizationSession.status === SessionStatus.Inprog && 
            <Button style={{backgroundColor:"#1D5F35"}} disabled={memorizationChaptersState.length === 0} variant="primary" onClick={onClickCompleteSession}>
              Afslut Session
            </Button>}
            {<Button style={{backgroundColor:"#1D5F35"}} variant="secondary" onClick={onCloseModal}>
              Luk
            </Button>}
          </Modal.Footer>
        </Modal>}
        {isModalMinimized &&
            <div style={{
              position: "fixed",
              bottom: "10px",
              right: "10px",
              background: "#f0f0f0",
              border: "1px solid #ccc",
              padding: "10px 20px",
              cursor: "pointer",
              zIndex: 1050,
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }} className="modal-minimized" onClick={handleRestore}>
            <span>Igangværende session. Tryk for at forstørre</span>
          </div>
          }
      </>
    );
}

export default MemorizationSessionTeacherModal;